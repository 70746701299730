import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import {
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { FeatureViewUrl } from "@bucketco/shared/urls";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";
import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import FormRadioGroup from "@/common/components/Form/FormRadioGroup";
import FormSubmit from "@/common/components/Form/FormSubmit";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import useOutsideClickPopover from "@/common/hooks/useOutsideClickPopover";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";
import { useFeaturesTableState } from "@/feature/hooks/useFeaturesTableState";

const FeatureViewFormSchema = z.discriminatedUnion("mode", [
  z.object({
    mode: z.literal("create"),
    name: z
      .string({
        required_error: "Feature view must have a name",
      })
      .min(1, "Feature view must have a name"),
  }),
  z.object({
    mode: z.literal("update"),
  }),
]);
type FeatureViewForm = z.input<typeof FeatureViewFormSchema>;

export function FeatureViewSave({ viewId }: { viewId?: string | null }) {
  const { currentApp, currentEnv } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorToast = useErrorToast();

  const {
    isDirty,
    isLoading: isStateLoading,
    sorting,
    onSortingChange,
    columnStates,
    onColumnStatesChange,
  } = useFeaturesTableState(viewId);

  const { data: features = [], isLoading: isFeaturesLoading } =
    useFeatureNamesData({
      viewId: viewId ?? undefined,
    });

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { contentRef, triggerRef } = useOutsideClickPopover({
    isOpen,
    onToggle,
  });

  const form = useForm<FeatureViewForm>({
    resolver: zodResolver(FeatureViewFormSchema),
    defaultValues: {
      mode: "update",
    },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (values: FeatureViewForm) => {
      try {
        if (values.mode === "update") {
          await api.patch<"/apps/:appId/feature-views/:viewId">(
            `/apps/${currentApp?.id}/feature-views/${viewId}`,
            {
              columns: columnStates,
              columnSortKey: sorting[0]?.id,
              columnSortDesc: sorting[0]?.desc,
            },
          );

          await queryClient.invalidateQueries({
            queryKey: featureViewsQueryKeys.list(currentApp?.id),
          });

          onSortingChange([]);
          onColumnStatesChange([]);
        } else if (values.mode === "create") {
          // Create a new feature view
          const res = await api.post<"/apps/:appId/feature-views">(
            `/apps/${currentApp?.id}/feature-views`,
            {
              name: values.name,
              featureIds: features.map((f) => f.id),
              columns: columnStates,
              columnSortKey: sorting[0]?.id,
              columnSortDesc: sorting[0]?.desc,
            },
          );

          // Clean up and redirect to the new feature view
          await queryClient.invalidateQueries({
            queryKey: featureViewsQueryKeys.list(currentApp?.id),
          });
          navigate(FeatureViewUrl(currentEnv!, res.data.id));
        }
      } catch (error) {
        errorToast({ description: `Failed to ${mode} the feature view` });
      }

      segmentAnalytics.track("Column saved", {
        view: "feature view",
      });
      onClose();
      form.reset();
    },
  });

  const handleSubmit = form.handleSubmit((values) => mutateAsync(values));
  const mode = form.watch("mode");

  if (!isDirty) return null;

  return (
    <HStack
      borderRightColor="appBorder"
      borderRightWidth={1}
      pr={3}
      spacing={3}
    >
      <Button
        color="dimmed"
        variant="ghost"
        onClick={() => {
          onSortingChange([]);
          onColumnStatesChange([]);
        }}
      >
        Reset
      </Button>
      <Popover
        autoFocus={false}
        closeOnEsc={true}
        isOpen={isOpen}
        placement="bottom-start"
        trigger="click"
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            ref={triggerRef}
            isLoading={isStateLoading || isFeaturesLoading || isPending}
            variant="primary"
            onClick={onToggle}
          >
            Save feature view
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={contentRef} w="auto">
          <PopoverBody minW="xs" p={5}>
            <PopoverArrow />
            <form onSubmit={handleSubmit}>
              <FormProvider {...form}>
                <FormRadioGroup
                  colorScheme="brand"
                  defaultValue="update"
                  name="mode"
                  options={[
                    { label: "Update feature view", value: "update" },
                    { label: "Create a new feature view", value: "create" },
                  ]}
                  size="sm"
                />
                {mode === "create" && (
                  <FormInput
                    autoComplete="off"
                    mt={2}
                    name="name"
                    placeholder="Feature view name"
                    size="sm"
                  />
                )}
                <FormSubmit isLoading={isPending} mt={4}>
                  {mode === "update"
                    ? "Update feature view"
                    : "Create feature view"}
                </FormSubmit>
              </FormProvider>
            </form>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
}
