import { Button, ButtonProps, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import omit from "lodash/omit";

import {
  PostToSlackNowArgsType,
  SlackChannel,
} from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import useSlackChannel from "@/common/hooks/useSlackChannel";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

type PostToSlackNowProps = ButtonProps & {
  slackChannel: SlackChannel | null;
  notification: Omit<PostToSlackNowArgsType, "channelId">;
};

export default function PostToSlackNow({
  slackChannel,
  notification,
  ...props
}: PostToSlackNowProps) {
  const { currentOrg } = useAuthContext();
  const toast = useToast();
  const errorToast = useErrorToast();

  const effectiveSlackChannel = useSlackChannel(slackChannel);

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      api.post<"/orgs/:orgId/slack/post-now">(
        `/orgs/${currentOrg?.id}/slack/post-now`,
        { ...notification, channelId: effectiveSlackChannel!.id },
        { timeout: 60000 },
      ),

    retry: 0,
    mutationKey: ["post-to-slack-now", notification, effectiveSlackChannel],

    onSuccess: () => {
      segmentAnalytics.track("Posted To Slack Now", {
        reportType: notification.reportType,
      });
      toast({
        title: "Posted to Slack!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: (error: any) => {
      console.error("Couldn't post to Slack", error);
      const msg =
        error.response.status === 404
          ? "invalid configuration"
          : "please try again";
      errorToast({
        description: `Couldn't post to Slack: ${msg}.`,
      });
    },
  });

  if (!effectiveSlackChannel) {
    return (
      <Text color="gray.500" fontSize="sm">
        Not configured
      </Text>
    );
  }

  return (
    <Button
      isLoading={isPending}
      leftIcon={<SlackHashLogo height="14" />}
      size="2xs"
      variant="outline"
      onClick={() => mutate()}
      {...omit(props, [
        "reportType",
        "channelId",
        "featureId",
        "appId",
        "envId",
      ])}
    >
      <Text isTruncated>
        {isPending
          ? "Sending..."
          : `Send to ${effectiveSlackChannel?.name || "channel"}`}
      </Text>
    </Button>
  );
}
