import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { FeatureListQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { getItemTree } from "@/common/utils/listItemTree";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export function useFeaturesData(
  params?: Omit<FeatureListQueryType, "envId">,
  enabled?: boolean,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: featureQueryKeys.list(appId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/features">(`/apps/${appId}/features`, {
          params: { ...params, useTargetingRules: true, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    refetchInterval(data) {
      if (data) {
        if ("hasBootstrappingFeature" in data && data.hasBootstrappingFeature) {
          return 500;
        }
      }
      return false;
    },
    select({ data, ...rest }) {
      const hasBootstrappingFeature = data.some(
        (feature) => feature.processingStatus === "bootstrapping",
      );
      return {
        data:
          rest.metadata.sortType === "hierarchical"
            ? getItemTree(data, "sortKey")
            : data,
        hasBootstrappingFeature,
        ...rest,
      };
    },
  });
}
