import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";

import { DataTableCustomizer } from "@/common/components/DataTable/DataTableCustomizer";
import DataTablePagination from "@/common/components/DataTable/DataTablePagination";
import {
  DataTableHasCustomization,
  DataTableHasPagination,
  DataTableHasSearch,
} from "@/common/components/DataTable/DataTableTypes";
import SearchInput from "@/common/components/SearchInput";
import { getId, getVisibilityFromIds } from "@/common/utils/datatable";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export type DataTableToolbarFunctionalityProps<TColumn extends string> =
  DataTableHasSearch &
    Pick<DataTableHasPagination, "canPaginate"> &
    Pick<DataTableHasCustomization<TColumn>, "canCustomize">;

type Props<
  TData extends { id: string; subRows?: TData[] },
  TColumn extends string,
> = DataTableToolbarFunctionalityProps<TColumn> & {
  table: Table<TData>;
  tableId?: string;
  frozenColumnId?: string;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  searchPlaceholder?: string;
  columnCustomizerFooter?: ReactNode;
};

export const DataTableToolbar = <
  TData extends { id: string; subRows?: TData[] },
  TColumn extends string,
>({
  table,
  tableId,
  frozenColumnId,
  leftActions,
  rightActions,
  searchPlaceholder = "Search",
  columnCustomizerFooter,
  // Search
  canSearch,
  searchQuery,
  onSearchQueryChange,
  // Paginate
  canPaginate,
  // Column States
  canCustomize,
}: Props<TData, TColumn>) => {
  if (
    !canSearch &&
    !canPaginate &&
    !canCustomize &&
    !leftActions &&
    !rightActions
  )
    return null;
  const columns = table.getAllFlatColumns();
  const columnOrder = table.getState().columnOrder;
  const columnVisible = table
    .getVisibleFlatColumns()
    .flatMap((c) => getId(c) ?? []);
  const { pageIndex, pageSize } = canPaginate
    ? table.getState().pagination
    : { pageIndex: 0, pageSize: 0 };
  const totalCount = table.getRowCount();

  return (
    <Flex
      alignItems="center"
      borderBottomColor="appBorder"
      borderBottomWidth={1}
      justifyContent="space-between"
      px={6}
      py={2}
      w="full"
    >
      <Flex alignItems="center" gap={3}>
        {canSearch && (
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchQuery}
            onChange={(event) => onSearchQueryChange(event.currentTarget.value)}
          />
        )}
        {leftActions}
      </Flex>
      <Flex alignItems="center" gap={3}>
        {rightActions}
        {canPaginate && (
          <DataTablePagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={totalCount}
            {...table}
          />
        )}
        {canCustomize && (
          <DataTableCustomizer
            columnOrder={columnOrder}
            columns={columns}
            columnVisible={columnVisible}
            customFooter={columnCustomizerFooter}
            frozenColumnId={frozenColumnId}
            onColumnOrderChange={(order) => {
              table.setColumnOrder(order);
              segmentAnalytics.track("Column Order Updated", {
                table: tableId,
              });
            }}
            onColumnRemove={() => {
              // todo: implement the remove event with companies table rework
            }}
            onColumnReset={() => {
              table.setState((prevState) => ({
                ...prevState,
                columnOrder: [],
                columnVisibility: {},
              }));
            }}
            onColumnVisibleChange={(visible) => {
              table.setColumnVisibility(getVisibilityFromIds(columns, visible));
              segmentAnalytics.track("Column Visibility Updated", {
                table: tableId,
              });
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
