import { useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { RiAddLine } from "react-icons/ri";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  BaseCompanyAttributeFilterSchema,
  BaseCompanyFeatureMetricFilterSchema,
  featureMetrics,
} from "@bucketco/shared/filter";
import { ColumnState } from "@bucketco/shared/types/columns";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import RuleAttribute from "@/common/components/Rule/RuleAttribute";
import RuleMetric from "@/common/components/Rule/RuleMetric";
import RuleType from "@/common/components/Rule/RuleType";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import useCompanyAttributesData from "@/company/data/useCompanyAttributesData";

const formSchema = z.discriminatedUnion("type", [
  BaseCompanyAttributeFilterSchema.omit({
    operator: true,
    values: true,
  }),
  BaseCompanyFeatureMetricFilterSchema.omit({
    operator: true,
    values: true,
  }),
]);

type FormValues = z.infer<typeof formSchema>;

type CompanyAttributeFieldsProps = {
  columnStates: ColumnState[];
};
function CompanyAttributeFields({ columnStates }: CompanyAttributeFieldsProps) {
  const { data: attributesRaw = [], isLoading } = useCompanyAttributesData();
  // only list custom attributes
  const attributes = attributesRaw.filter((i) => !i.system);

  const availableAttributes = useMemo(() => {
    return attributes
      ? attributes.filter(
          (a) => !columnStates?.find((c) => c.id === `attributes.${a.key}`),
        )
      : [];
  }, [attributes, columnStates]);

  return (
    <>
      <ManagedFormControl
        name="field"
        render={({ field }) => (
          <RuleAttribute
            {...field}
            attributes={availableAttributes}
            isLoading={isLoading}
          />
        )}
      />
    </>
  );
}

type FeatureMetricFieldsProps = {
  columnStates: ColumnState[];
};
function FeatureMetricFields({ columnStates }: FeatureMetricFieldsProps) {
  const selectedMetrics = useMemo(() => {
    return (
      columnStates?.reduce<Record<string, string[]>>((acc, columnState) => {
        const [key, id, metric] = columnState.id.split(".");
        if (key !== "featureMetrics") return acc;
        return {
          ...acc,
          [id]: [...(acc[id] ?? []), metric],
        };
      }, {}) ?? {}
    );
  }, [columnStates]);

  const selectedFeatureId = useWatch<FormValues>({ name: "featureId" });

  const availableMetrics = useMemo(() => {
    if (!selectedFeatureId) return featureMetrics;
    return featureMetrics.filter(
      (metric) => !selectedMetrics[selectedFeatureId]?.includes(metric),
    );
  }, [selectedFeatureId, selectedMetrics]);

  return (
    <>
      <ManagedFormControl
        name="featureId"
        render={({ field }) => (
          <FeatureAutocompleteSelect
            showClearButton={false}
            {...field}
            itemFilterFn={(feature) => {
              if (!selectedMetrics[feature.id]) return true;
              return featureMetrics.some((metric) => {
                return !selectedMetrics[feature.id].includes(metric);
              });
            }}
            value={field.value}
            onChange={(nextFeature) => {
              if (nextFeature) {
                field.onChange(nextFeature.id);
              }
            }}
          />
        )}
      />
      <ManagedFormControl
        name="metric"
        render={({ field }) => (
          <RuleMetric {...field} availableMetrics={availableMetrics} />
        )}
      />
    </>
  );
}

type Props = {
  columnStates: ColumnState[];
  isDisabled?: boolean;
  setColumnStates: (nextColumnStates: ColumnState[]) => void;
};

export const AddColumnPopover = ({
  columnStates,
  isDisabled,
  setColumnStates,
}: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      type: "companyAttribute",
      field: undefined,
    },
  });

  const columnType = useWatch({ control: form.control, name: "type" });

  const onSubmit = form.handleSubmit((data) => {
    switch (data.type) {
      case "companyAttribute":
        setColumnStates([
          ...columnStates,
          {
            id: `attributes.${data.field}`,
            shown: true,
          },
        ]);
        form.reset({ type: "companyAttribute" });
        segmentAnalytics.track("Custom Attribute Column Added", {
          table: "companies",
          attribute: data.field,
        });
        break;

      case "featureMetric":
        setColumnStates([
          ...columnStates,
          {
            id: `featureMetrics.${data.featureId}.${data.metric}`,
            shown: true,
          },
        ]);
        form.reset({ type: "featureMetric" });
        segmentAnalytics.track("Custom Feature Column Added", {
          table: "companies",
          featureId: data.featureId,
          metric: data.metric,
        });
        break;
    }
  });

  return (
    <Popover closeOnBlur={false} placement="left-start">
      <PopoverTrigger>
        <Button
          fontWeight="normal"
          iconSpacing={1}
          isDisabled={isDisabled}
          leftIcon={<RiAddLine size={16} />}
          size="sm"
          variant="outline"
        >
          Add column
        </Button>
      </PopoverTrigger>
      <PopoverContent maxW="64">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p={5}>
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <Flex align="flex-start" direction="column" gap={2}>
                <Text color="dimmed" mb={2}>
                  Add column
                </Text>
                <ManagedFormControl
                  name="type"
                  render={({ field }) => (
                    <RuleType
                      {...field}
                      allowed={["companyAttribute", "featureMetric"]}
                    />
                  )}
                />
                {columnType === "companyAttribute" ? (
                  <CompanyAttributeFields columnStates={columnStates} />
                ) : columnType === "featureMetric" ? (
                  <FeatureMetricFields columnStates={columnStates} />
                ) : null}
                <FormSubmitLegacy mt={2} size="sm">
                  Add
                </FormSubmitLegacy>
              </Flex>
            </form>
          </FormProvider>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
