import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { uploadioUrlSchema } from "./schemas/uploadioUrlSchema";
import { APIResponse } from "./api";
import { AppDTO, CreateAppWithOrgArgsSchema } from "./appAPI";

export const CreateOrgArgsSchema = z
  .object({
    name: nameSchema,
    logoUrl: uploadioUrlSchema.nullish(),
    app: CreateAppWithOrgArgsSchema,
  })
  .strict();

export type CreateOrgArgsType = z.infer<typeof CreateOrgArgsSchema>;
export const PatchOrgArgsSchema = CreateOrgArgsSchema.pick({
  name: true,
  logoUrl: true,
  allowDomainJoin: true,
}).partial();
export type PatchOrgArgsType = z.infer<typeof PatchOrgArgsSchema>;

/**
 * This should be kept in sync with the prisma schema at all times
 */
export const AccessLevels = [
  "starter",
  "pro",
  "business",
  "enterprise",
] as const;
export const AccessLevelSchema = z.enum(AccessLevels);
export type AccessLevel = z.infer<typeof AccessLevelSchema>;

export interface Org {
  id: string;
  name: string;
  logoUrl: string | null;
  apps: AppDTO[];
  hasSlackConnection: boolean;
  inviteKey: string;
  createdAt: string;
  updatedAt: string;
  trialEndsAt: string | null;
  suspendedAt: string | null;
  accessLevel: AccessLevel;
  domain: string | null;
  domainAutoJoin: boolean;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  avatar: string | null;
}

export interface TrackingUsage {
  month: string;
  trackedUsers: number;
}

export interface OrgAPI {
  "/orgs": {
    GET: {
      response: APIResponse<{
        orgs: Org[];
      }>;
    };
    POST: {
      response: APIResponse<{
        org: Org;
      }>;
      body: CreateOrgArgsType;
    };
  };
  "/orgs/:orgId": {
    PATCH: {
      response: APIResponse<{
        org: Org;
      }>;
      params: {
        orgId: string;
      };
      body: PatchOrgArgsType;
    };
  };
  "/orgs/:orgId/members": {
    GET: {
      response: APIResponse<Member[]>;
      params: {
        orgId: string;
      };
    };
  };
  "/orgs/:orgId/invite/refresh": {
    POST: {
      response: APIResponse<Org>;
      params: {
        orgId: string;
      };
    };
  };
  "/orgs/:orgId/monthly-tracked-users": {
    GET: {
      response: APIResponse<Array<TrackingUsage>>;
      params: {
        orgId: string;
      };
    };
  };
}
