import React, { useCallback, useState } from "react";
import {
  RiCloseLine,
  RiFlashlightLine,
  RiPauseMiniLine,
  RiSearchLine,
} from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Center,
  Code,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Link,
  List,
  ListItem,
  TableRowProps,
  Tbody,
  Td,
  Text,
  TextProps,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

import { Event } from "@bucketco/shared/eventAPI";
import {
  CompanyUrl,
  GlobalSettingsUrl,
  NewFeatureUrl,
} from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import AutocompleteInput from "@/common/components/AutocompleteInput";
import { DocumentationButton } from "@/common/components/CommonIntegrationButtons";
import EmptyState from "@/common/components/EmptyState";
import InfiniteAnimation from "@/common/components/InfiniteAnimation";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import SectionDescription from "@/common/components/SectionDescription";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout, {
  SidebarContainer,
  SidebarSection,
  SidebarTable,
} from "@/common/components/SidebarLayout";
import SimpleTable from "@/common/components/SimpleTable";
import TimestampCell from "@/common/components/TimestampCell";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import { useEventNames } from "@/common/data/useEventNames";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { IntegrationKey } from "@/environment/components/IntegrationKey";
import { useEnvironments } from "@/environment/hooks/useEnvironments";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

const MAX_EVENTS_COUNT = 50;
const DAYS_HISTORY = 3;

// animate event items as they appearttttt
export const MotionTr = motion<TableRowProps>(Tr);
const itemTransition = {
  type: "tween",
  duration: 0.2,
};
const itemVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const EventItem = React.memo(function EventItem({
  event,
  isActive,
  onClick,
}: {
  event: Event;
  index?: number;
  isActive?: boolean;
  onClick?: () => void;
}) {
  const activeBg = useColorModeValue("gray.50", "gray.750");
  const attributeCount = Object.keys(event.attributes).length;

  return (
    <MotionTr
      key={`${event.name}-${event.timestamp}-${event.user.name}`}
      animate="visible"
      bg={isActive ? activeBg : undefined}
      initial="hidden"
      transition={itemTransition as any}
      variants={itemVariants}
      layout
      onClickCapture={onClick}
    >
      <Td maxW="230px">
        <HStack spacing={2}>
          <Box color="dimmed">
            <PointerPressIcon height="16px" width="16px" />
          </Box>
          <Text fontSize="md" fontWeight="medium">
            {event.name}
          </Text>
          {attributeCount && (
            <Text color="dimmed" fontSize="xs">
              {attributeCount} {pluralize("attribute", attributeCount)}
            </Text>
          )}
        </HStack>
      </Td>
      <Td>
        <UserCell event={event} fontSize="sm" maxW="3xs" />
      </Td>
      <Td>
        <CompanyCell event={event} fontSize="sm" maxW="3xs" />
      </Td>
      <Td fontSize="sm">
        <TimestampCell value={event.timestamp} autoUpdate />
      </Td>
    </MotionTr>
  );
});

const eventKey = (event: Event) => `${event.name}_${event.timestamp}`;

const ListEvents = React.memo(function ListEvent({
  events,
  isLoading,
  activeEvent,
  setActiveEvent,
}: {
  events: Event[];
  isLoading: boolean;
  activeEvent: Event | null;
  setActiveEvent: React.Dispatch<React.SetStateAction<Event | null>>;
}) {
  const { currentEnv } = useAuthContext();
  if (events.length === 0) {
    return (
      <EmptyState
        description={
          <>
            Events will show up here as soon as they come in. Note: tracking
            data is{" "}
            <Link
              as={RouterLink}
              to={GlobalSettingsUrl(currentEnv!, "app-environments")}
            >
              environment
            </Link>{" "}
            specific!
          </>
        }
        h={"100%"}
        icon={<PointerPressIcon height="48px" width="48px" />}
        isLoading={isLoading}
        title={`No events past ${DAYS_HISTORY} days`}
      />
    );
  }

  return (
    <SimpleTable
      columns={["Event", "User", "Company", "Time"]}
      rows={events}
      rowTemplate={(event, index) => (
        <EventItem
          key={eventKey(event)}
          event={event}
          index={index}
          isActive={
            activeEvent !== null && eventKey(event) === eventKey(activeEvent)
          }
          onClick={() => setActiveEvent(event)}
        />
      )}
      variant="clickable"
    />
  );
});

function SidebarInstructions() {
  const { currentEnv } = useAuthContext();
  const envsQuery = useEnvironments();

  return (
    <SidebarContainer>
      <SidebarSection>
        <SectionHeading>Getting started guide</SectionHeading>
        <SectionDescription>
          Use Segment (several languages) or the Bucket SDK (JS) to send data to
          Bucket.
        </SectionDescription>
        <Box>
          <DocumentationButton
            onClick={() =>
              segmentAnalytics.track("Tracking Getting Started Link Clicked")
            }
          />
        </Box>
      </SidebarSection>
      <SidebarSection>
        <SectionHeading>Integration keys</SectionHeading>
        <SectionDescription>
          Provide these keys to Segment or the Bucket SDK to send data to the
          corresponding app environment.{" "}
          <Link
            href="https://docs.bucket.co/introduction/concepts/app/environment"
            target="_blank"
          >
            Learn more
          </Link>
        </SectionDescription>
        {envsQuery.isLoading ? (
          <AnimatedSpinner size="sm" show />
        ) : !envsQuery.isSuccess ? (
          <Alert status="warning">
            <AlertIcon />
            Unable to load environment keys
          </Alert>
        ) : (
          <Flex direction="column" gap={4}>
            {[envsQuery.data.production, ...envsQuery.data.nonProduction].map(
              (env) => (
                <VStack key={env.id} align="flex-start">
                  <EnvironmentDisplayName environment={env} />
                  <IntegrationKey value={env.publishableKey} w="100%" />
                  <IntegrationKey value={env.secretKey} w="100%" />
                </VStack>
              ),
            )}
            <Link
              as={RouterLink}
              to={GlobalSettingsUrl(currentEnv!, "app-environments")}
            >
              Manage environments
            </Link>
          </Flex>
        )}
      </SidebarSection>
    </SidebarContainer>
  );
}

export function SidebarEventDetails({
  event,
  onClose,
}: {
  event: Event | null;
  onClose: () => void;
}) {
  const { currentEnv } = useAuthContext();
  const codeBg = useColorModeValue("gray.50", "gray.750");

  if (event === null) return null;

  const attributeCount = Object.keys(event.attributes).length;

  return (
    <SidebarContainer position="sticky" top={0}>
      <SidebarSection>
        <Flex align="center" direction="row">
          <SectionHeading flexGrow={1}>Event details</SectionHeading>
          <IconButton
            aria-label="Close event details"
            icon={<RiCloseLine size={16} />}
            size="2xs"
            variant="ghost"
            isRound
            onClick={onClose}
          />
        </Flex>
        <Flex direction="column" gap={2}>
          <Text color="dimmed" fontSize="sm" fontWeight="medium">
            Name
          </Text>
          <Code bg={codeBg} borderRadius="base" px={3} py={1.5}>
            {event?.name}
          </Code>
        </Flex>
        {attributeCount > 0 && (
          <Flex direction="column" gap={2}>
            <Text color="dimmed" fontSize="sm" fontWeight="medium">
              Attributes
            </Text>
            <Code
              bg={codeBg}
              borderRadius="base"
              overflowX="scroll"
              px={3}
              py={1.5}
              whiteSpace="pre"
            >
              {JSON.stringify(event?.attributes, null, 2)}
            </Code>
          </Flex>
        )}
        <SidebarTable layout="fixed">
          <colgroup>
            <col width="90"></col>
            <col></col>
          </colgroup>
          <Tbody>
            <Tr>
              <Td color="dimmed" fontWeight="medium">
                User
              </Td>
              <Td textAlign="right">
                <UserCell event={event} fontSize="sm" />
              </Td>
            </Tr>
            <Tr>
              <Td color="dimmed" fontWeight="medium">
                Company
              </Td>
              <Td maxW="0" textAlign="right">
                <CompanyCell
                  event={event}
                  fontSize="sm"
                  textDecoration="none"
                  showAvatar
                />
              </Td>
            </Tr>
            <Tr>
              <Td color="dimmed" fontWeight="medium">
                Timestamp
              </Td>
              <Td textAlign="right">
                <TimestampCell value={event.timestamp} autoUpdate />
              </Td>
            </Tr>
          </Tbody>
        </SidebarTable>
      </SidebarSection>

      <SidebarSection>
        <SectionHeading>Related features</SectionHeading>

        <Box minH={6}>
          <FilteredFeatureList eventIdentifier={event.name} />
        </Box>

        <Box>
          <Button
            as={RouterLink}
            colorScheme="brand"
            size="sm"
            to={NewFeatureUrl(currentEnv!, {
              source: "event",
              eventIdentifier: event.name,
            })}
          >
            Track new feature
          </Button>
        </Box>
      </SidebarSection>
    </SidebarContainer>
  );
}

function FilteredFeatureList({ eventIdentifier }: { eventIdentifier: string }) {
  const {
    data = [],
    isLoading,
    isError,
  } = useFeatureNamesData({ eventIdentifier });

  if (isLoading) {
    return (
      <Center>
        <AnimatedSpinner size="sm" show />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert status="warning">
        <AlertIcon />
        Unable to load features
      </Alert>
    );
  }

  if (data.length === 0) {
    return <Text color="dimmed">No related features</Text>;
  }

  return (
    <Flex as={List} direction="column" gap={4}>
      {data.map((f) => (
        <ListItem key={f.id}>
          <FeatureDisplay feature={f} link />
        </ListItem>
      ))}
    </Flex>
  );
}

function UserCell({
  event,
  ...textProps
}: TextProps & {
  event: Event;
}) {
  return (
    <Text {...textProps} isTruncated>
      {event.user.name || event.user.id}
    </Text>
  );
}

function CompanyCell({
  event,
  showAvatar = false,
  ...textProps
}: TextProps & {
  showAvatar?: boolean;
  event: Event;
}) {
  const { currentEnv } = useAuthContext();

  if (event.user.companies.length > 0) {
    return (
      <Text {...textProps} isTruncated>
        {event.user.companies.map((c) => (
          <Link key={c.id} as={RouterLink} to={CompanyUrl(currentEnv!, c.id)}>
            {showAvatar && <Avatar mr={1} name={c.name || c.id} size="2xs" />}
            {c.name || c.id}
          </Link>
        ))}
      </Text>
    );
  }

  return (
    <HStack display="inline-flex">
      <Text {...textProps} color="red.500" fontStyle="italic">
        Missing company
      </Text>
      <InfoIconTooltip
        docsURL="https://docs.bucket.co/introduction/concepts/company/associating-users-and-companies"
        text="The user of this event hasn't been associated with a company."
      />
    </HStack>
  );
}

function EventNameFilter({
  value = "",
  onChange,
  size = "sm",
  distinctEvents,
}: {
  value?: string;
  onChange: (value: string) => void;
  size?: InputProps["size"];
  distinctEvents: string[];
}) {
  const searchIconColor = useColorModeValue("gray.400", "gray.600");
  return (
    <AutocompleteInput
      maxSuggestions={50}
      search={value}
      suggestions={distinctEvents.map((e) => ({ key: e, label: e }))}
      onValuePick={(val) => onChange(val.key)}
    >
      <InputGroup size={size}>
        <InputLeftElement color={!value ? searchIconColor : undefined}>
          <RiSearchLine size={16} />
        </InputLeftElement>
        <Input
          autoComplete="off"
          placeholder="Filter by event name"
          type="search"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
    </AutocompleteInput>
  );
}

export default function TrackingRecent() {
  const { envId, appId } = useCurrentEnv();
  const lastWeekTS = dayjs().subtract(DAYS_HISTORY, "days").valueOf();
  const [latestTS, setLatestTS] = useState<number>(lastWeekTS);
  const [cachedEvents, setCachedEvents] = useState<Event[]>([]);

  const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);

  const refetchInterval = () => (shouldRefetch ? 1000 : false);

  const [eventNameFilter, setEventNameFilter] = useState<string>();
  const { data: distinctEvents = [] } = useEventNames();

  const queryClient = useQueryClient();

  const { isLoading, isError } = useQuery({
    queryKey: ["environment", envId, "trackedEvents", eventNameFilter],

    queryFn: () =>
      api
        .get<"/apps/:appId/events/latest">(`/apps/${appId}/events/latest`, {
          params: {
            lastTS: String(latestTS),
            eventName: eventNameFilter,
            envId: envId!,
          },
        })
        .then((res) => res.data.events)
        .then((events) => {
          if (events && events.length > 0) {
            // save new events
            setCachedEvents((cachedEvents) =>
              uniqWith([...events, ...cachedEvents], isEqual).slice(
                0,
                MAX_EVENTS_COUNT,
              ),
            );

            // Update timestamp
            const latestIncomingTS = Date.parse(events[0].timestamp);
            setLatestTS(latestIncomingTS);

            // refetch distinct event names if new events has arrived
            const newEvents = events.some(
              (event) => !distinctEvents.includes(event.name),
            );
            if (newEvents) {
              queryClient.refetchQueries({
                queryKey: commonQueryKeys.distinctEventNames(appId, envId),
              });
            }
          }

          return events;
        }),

    gcTime: 0,
    enabled: !!appId && !!envId,
    refetchInterval: refetchInterval,
  });

  const onEventNameFilterChange = useCallback(
    (val: string) => {
      setLatestTS(lastWeekTS);
      setCachedEvents([]);
      setEventNameFilter(val);
    },
    [lastWeekTS],
  );

  const [activeEvent, setActiveEvent] = useState<Event | null>(null);

  return (
    <SidebarLayout
      flexGrow={1}
      sidebarContent={
        activeEvent !== null ? (
          <SidebarEventDetails
            event={activeEvent}
            onClose={() => setActiveEvent(null)}
          />
        ) : (
          <SidebarInstructions />
        )
      }
    >
      <Box width="100%">
        <Flex align="center" justify={"space-between"} px={6} py={3}>
          <Box>
            <EventNameFilter
              distinctEvents={distinctEvents}
              value={eventNameFilter}
              onChange={onEventNameFilterChange}
            />
          </Box>
          <Button
            color={shouldRefetch ? "inherit" : "gray.500"}
            rightIcon={
              shouldRefetch ? (
                <InfiniteAnimation>
                  <RiFlashlightLine size={16} />
                </InfiniteAnimation>
              ) : (
                <RiPauseMiniLine size={20} />
              )
            }
            size="sm"
            variant="outline"
            onClick={() => setShouldRefetch((cur) => !cur)}
          >
            {shouldRefetch ? "Live" : "Paused"}
          </Button>
        </Flex>
        <Divider />
        {isError ? (
          <div>Error&apos;d {}</div>
        ) : (
          <ListEvents
            activeEvent={activeEvent}
            events={cachedEvents}
            isLoading={isLoading}
            setActiveEvent={setActiveEvent}
          />
        )}
      </Box>
    </SidebarLayout>
  );
}
