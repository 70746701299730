import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";
import { HStack, VisuallyHidden } from "@chakra-ui/react";

import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import HeaderLayout from "@/common/components/HeaderLayout";
import SlackStatusBadge from "@/common/components/slack/SlackStatusBadge";
import { SubsegmentPicker } from "@/common/components/SubsegmentPicker";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFeatureViewParam } from "@/common/hooks/useFeatureViewParam";
import { FeaturesTable } from "@/feature/components/FeaturesTable";
import { FeatureViewPicker } from "@/feature/components/FeatureViewPicker";
import { FeatureViewSave } from "@/feature/components/FeatureViewSave";

export default function Features() {
  const { currentEnv } = useAuthContext();
  const errorToast = useErrorToast();
  const [viewId, setViewId] = useFeatureViewParam();

  const { data: featureViews, isSuccess } = useFeatureViewsData();
  const allFeaturesView = featureViews?.find((v) => v.isAllFeatureView);
  const currentView = viewId
    ? featureViews?.find((v) => v.id === viewId)
    : allFeaturesView;

  useEffect(() => {
    if (viewId && isSuccess && !currentView) {
      errorToast({
        description: `Could not find the feature view, redirecting to All features`,
        duration: 4000,
      });

      if (allFeaturesView) {
        setViewId(allFeaturesView.id);
      }
    }
  }, [viewId, isSuccess, currentView, errorToast, setViewId, allFeaturesView]);

  if (!currentView) return;

  return (
    <>
      <VisuallyHidden>
        <h1>{currentView.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <HStack spacing={3}>
            <FeatureViewSave viewId={currentView.id} />
            <SubsegmentPicker
              menuDescription="Only include companies that match targeting rules and belong to the following segment:"
              placeholder="Apply subsegment"
            />
          </HStack>
        }
        title={
          <HStack ml={-3} spacing={1}>
            <FeatureViewPicker currentView={currentView} />
            {currentEnv && (
              <SlackStatusBadge
                active={currentView.slackWeeklyReport}
                settingsLink={GlobalSettingsUrl(
                  currentEnv,
                  "app-feature-views",
                )}
                slackChannel={currentView.slackChannel}
                tooltip="Weekly report is sent to the Slack channel containing the all features in the view and highlighting the new features added in the last week."
              />
            )}
          </HStack>
        }
      >
        <Helmet>
          <title>{`Features › ${currentView.name}`}</title>
        </Helmet>
        <FeaturesTable viewId={currentView.id} />
        <Outlet />
      </HeaderLayout>
    </>
  );
}
