import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export default function useFeatureFeatureViewsUpdateMutation(
  featureId: string,
) {
  const { appId } = useCurrentEnv();
  const toast = useToast();
  const queryClient = useQueryClient();
  const queryKey = featureQueryKeys.featureViews(appId, featureId);

  return useMutation<
    string[],
    AxiosError<ErrorResponse>,
    string[],
    { oldFeatureViewIds?: string[] }
  >({
    mutationFn: (featureViewIds) =>
      api
        .put<"/apps/:appId/features/:featureId/views">(
          `/apps/${appId}/features/${featureId}/views`,
          featureViewIds,
        )
        .then((res) => res.data),

    mutationKey: queryKey,

    async onMutate(newFeatureViewIds) {
      await queryClient.cancelQueries({ queryKey: queryKey, exact: true });

      const oldFeatureViewIds = queryClient.getQueryData<string[]>(queryKey);

      // Optimistic update: Immediately reflect updated views
      queryClient.setQueryData<string[]>(queryKey, newFeatureViewIds);

      return { oldFeatureViewIds };
    },

    onSuccess(views) {
      // TODO: Invalidate all and new views the feature appeared in

      toast({
        title: "Feature views updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      segmentAnalytics.track("Feature Views of Feature Updated", {
        feature_views: views.length,
      });
    },

    onError(_, __, context) {
      // Roll back to previous views
      if (typeof context?.oldFeatureViewIds !== "undefined") {
        queryClient.setQueryData<string[]>(queryKey, context.oldFeatureViewIds);
      }

      toast({
        title: "Unable to update feature views",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });
}
