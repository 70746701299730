import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RiAddLine } from "react-icons/ri";
import { Link as RouterLink, Outlet, To } from "react-router-dom";
import { Button, ButtonGroup, Divider, Flex, Link } from "@chakra-ui/react";

import { FunnelStep, FunnelStepList } from "@bucketco/shared/featureAPI";
import { FeedbackDTO, FeedbackQueryType } from "@bucketco/shared/feedbackAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { CompanyAutocompleteSelect } from "@/common/components/CompanyAutocompleteSelect";
import EmptyState from "@/common/components/EmptyState";
import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import HeaderLayout from "@/common/components/HeaderLayout";
import {
  SatisfactionFilter,
  spanToScore,
} from "@/common/components/SatisfactionFilter";
import TablePagination from "@/common/components/TablePagination";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTableLegacy from "@/common/hooks/useDataTableLegacy";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { StarsFunnelFilter } from "@/feature/components/StarsFunnelFilter";
import FeedbackEmptyStateIllustration from "@/feedback/components/FeedbackEmptyStateIllustration";
import FeedbackTable from "@/feedback/components/FeedbackTable";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";

const defaultFunnelSteps = [...FunnelStepList];

export default function Feedbacks() {
  const { envId, appId } = useCurrentEnv();

  const [satisfactionFilter, setSatisfactionFilter] =
    useSearchParam<SatisfactionSpan>("satisfaction");
  const [funnelStepsFilter, setFunnelStepsFilter] =
    useSearchArrayParam<FunnelStep>("funnelStep", {
      fallback: defaultFunnelSteps,
    });
  const [featureFilter, setFeatureFilter] = useSearchParam("featureFilter");
  const [companyFilter, setCompanyFilter] = useSearchParam("companyfilter");

  const tableConfiguration = useLocalStorageTableConfiguration("Feedbacks", {
    defaultColumns: [],
    defaultSort: {
      id: "timestamp",
      desc: true,
    },
  });

  const table = useDataTableLegacy<
    FeedbackDTO,
    Omit<FeedbackQueryType, "sortOrder" | "pageIndex" | "pageSize" | "envId">
  >({
    apiCacheKey: feedbackQueryKeys.list(appId, envId),
    apiHandler: (queryParams) => () => {
      return api
        .get<"/apps/:appId/feedbacks">(`/apps/${appId}/feedbacks`, {
          params: { ...queryParams, envId: envId! },
        })
        .then((res) => res.data);
    },
    defaultQueryParams: {
      sortBy: "timestamp",
      funnelSteps: funnelStepsFilter,
      satisfaction: spanToScore(satisfactionFilter),
      featureId: featureFilter,
      companyId: companyFilter,
    },
    apiOptions: {
      enabled: !!appId && !!envId,
    },
  });

  useEffect(() => {
    segmentAnalytics.page("Feedbacks");
  }, []);

  const hasAnyFilters =
    satisfactionFilter ||
    featureFilter ||
    companyFilter ||
    funnelStepsFilter.length < defaultFunnelSteps.length;

  return (
    <>
      <Helmet>
        <title>Feedback</title>
      </Helmet>
      <HeaderLayout
        actions={
          <ButtonGroup spacing={4}>
            <Button
              as={RouterLink}
              leftIcon={<RiAddLine size={16} />}
              size="sm"
              to="new"
              variant="outline"
            >
              New feedback
            </Button>
          </ButtonGroup>
        }
        title="Feedback"
      >
        <Flex direction="column" flex="1 1 auto" width="100%">
          <Flex justify={"space-between"} px={6} py={3}>
            <Flex align="center" gap={4}>
              <StarsFunnelFilter
                disabledSteps={["segment"]}
                value={funnelStepsFilter}
                onChange={(newSteps) => {
                  setFunnelStepsFilter(newSteps);

                  table.fetchData({
                    funnelSteps: newSteps,
                    pageIndex: 0,
                  });

                  segmentAnalytics.track("Feedbacks Filter Updated", {
                    type: "funnelSteps",
                    value: funnelStepsFilter,
                  });
                }}
              />
              <SatisfactionFilter
                size="sm"
                value={satisfactionFilter}
                onChange={(value) => {
                  setSatisfactionFilter(value);

                  table.fetchData({
                    satisfaction: spanToScore(value),
                    pageIndex: 0,
                  });

                  segmentAnalytics.track("Feedbacks Filter Updated", {
                    type: "satisfaction",
                    value: satisfactionFilter,
                  });
                }}
              />
              <FeatureAutocompleteSelect
                placeholder="Filter by feature"
                value={featureFilter}
                onChange={(value) => {
                  setFeatureFilter(value?.id);

                  table.fetchData({
                    featureId: value?.id,
                    pageIndex: 0,
                  });

                  segmentAnalytics.track("Feedbacks Filter Updated", {
                    type: "featureFilter",
                    value: featureFilter,
                  });
                }}
              />
              <CompanyAutocompleteSelect
                placeholder="Filter by company"
                value={companyFilter ? { id: companyFilter } : undefined}
                onChange={(company) => {
                  setCompanyFilter(company?.id);

                  table.fetchData({
                    companyId: company?.id,
                    pageIndex: 0,
                  });

                  segmentAnalytics.track("Feedbacks Filter Updated", {
                    type: "companyFilter",
                    value: company?.id,
                  });
                }}
              />
              <AnimatedSpinner
                color="dimmed"
                show={table.isLoading}
                size="sm"
              />
            </Flex>
            <TablePagination
              canPaginate={table.canPaginate}
              label="Entries"
              pageCount={table.pageCount}
              pageIndex={table.data?.pageIndex}
              pageSize={table.data?.pageSize}
              paginateActions={table.paginateActions}
              totalCount={table.data?.totalCount}
            />
          </Flex>
          <Divider />
          {table.isLoading ? null : table.data?.data.length === 0 ? (
            <EmptyState
              description={
                hasAnyFilters ? (
                  "Try adjusting the filters"
                ) : (
                  <>
                    You can start collecting feedback automatically with{" "}
                    <Link href="https://docs.bucket.co/product-handbook/automated-feedback-surveys">
                      automated feedback surveys
                    </Link>{" "}
                    through our{" "}
                    <Link
                      href="https://docs.bucket.co/quickstart/supported-languages-frameworks"
                      target="_blank"
                    >
                      SDKs
                    </Link>{" "}
                    or{" "}
                    <Link
                      href="https://docs.bucket.co/api/http-api"
                      target="_blank"
                    >
                      HTTP API
                    </Link>
                  </>
                )
              }
              flexGrow={1}
              icon={<FeedbackEmptyStateIllustration />}
              title={hasAnyFilters ? "No feedback found" : "No feedback yet"}
            />
          ) : (
            <FeedbackTable
              columnOrder={[
                "score",
                "question",
                "comment",
                "userName",
                "companyName",
                "companyFunnelStep",
                "featureName",
                "source",
                "timestamp",
                "actions",
              ]}
              columnStates={tableConfiguration.columns}
              createEditURL={createEditURL}
              data={table.data?.data ?? []}
              fetchData={table.fetchData}
              isFetching={table.isFetching}
              pageCount={table.pageCount}
              setCanPaginate={table.setCanPaginate}
              setColumnStates={tableConfiguration.setColumns}
              setPaginateActions={table.setPaginateActions}
              setSortBy={tableConfiguration.setSort}
              sortBy={tableConfiguration.sort}
              viewName="Features"
            />
          )}
        </Flex>
      </HeaderLayout>
      <Outlet />
    </>
  );
}

function createEditURL(feedback: FeedbackDTO): To {
  return `edit/${feedback.id}`;
}
