import { useFormContext } from "react-hook-form";
import { Button, ButtonProps } from "@chakra-ui/react";

type FormSubmitProps = Omit<ButtonProps, "type">;

export default function FormSubmitLegacy({
  children = "Save",
  ...rest
}: FormSubmitProps) {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      isLoading={isSubmitting}
      loadingText="Saving"
      type="submit"
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
}
