import slugMod from "slug";

import { SatisfactionSpan } from "./schemas/satisfactionScore";
import { CsvExportIntervalType } from "./exportAPI";
import { FunnelStep } from "./featureAPI";
import { UIFilter } from "./filter";

type Primitive =
  | number
  | number[]
  | string
  | string[]
  | boolean
  | bigint
  | symbol
  | null
  | undefined;

export type UrlArgs = { id: string; name: string };

export const serializeFilterParam = (value: UIFilter) => {
  return btoa(JSON.stringify(value));
};

export const deserializeFilterParam = (value: string): UIFilter | null => {
  try {
    return JSON.parse(atob(value));
  } catch {
    return null;
  }
};

// Remove "undefined" string values from query params
export function cleanQueryParams(
  params?: string | Record<string, Primitive> | string[][] | URLSearchParams,
): URLSearchParams {
  if (
    !params ||
    typeof params === "string" ||
    params instanceof URLSearchParams ||
    Array.isArray(params)
  ) {
    return new URLSearchParams(params);
  }
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((v) => searchParams.append(key, String(v)));
    } else if (value !== undefined && value !== null) {
      searchParams.set(key, String(value));
    }
  }
  return searchParams;
}

export function slug({ id, name }: UrlArgs) {
  return `${slugMod(name).substring(0, 15)}-${id}`;
}

export function LoginUrl(
  params?: string | Record<string, string> | string[][] | URLSearchParams,
) {
  const searchParams = cleanQueryParams(params);
  return `/login?${searchParams.toString()}`;
}

export function SignupUrl(
  params?: string | Record<string, string> | string[][] | URLSearchParams,
) {
  const searchParams = cleanQueryParams(params);
  return `/signup?${searchParams.toString()}`;
}

export function AppUrl(app: UrlArgs): string {
  return `/apps/${slug(app)}`;
}

export function EnvironmentUrl(environment: UrlArgs): string {
  return `/envs/${slug(environment)}`;
}

export function NewFeatureUrl(
  env: UrlArgs,
  params?: string | Record<string, string> | string[][] | URLSearchParams,
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/new?${searchParams.toString()}`;
}

export function FeatureUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}?${searchParams.toString()}`;
}

export function FeatureAnalyzeUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/analyze?${searchParams.toString()}`;
}

export function FeatureTargetingUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/targeting?${searchParams.toString()}`;
}

export function FeatureReportUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/report?${searchParams.toString()}`;
}

export function FeatureSettingsUrl(
  env: UrlArgs,
  feature: UrlArgs,
  section: FeatureSettingsSections = "general",
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/settings/${section}?${searchParams.toString()}`;
}

export function FeatureIntegrationsUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/integrations?${searchParams.toString()}`;
}

export function FeatureCompaniesUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: {
    subsegment?: string;
    funnelSteps?: FunnelStep[];
    satisfaction?: SatisfactionSpan;
  },
): string {
  const searchParams = cleanQueryParams();
  if (params?.subsegment) {
    searchParams.set("subsegment", params?.subsegment);
  }
  if (params?.funnelSteps?.length) {
    params?.funnelSteps.forEach((step) =>
      searchParams.append("funnelStep", step),
    );
  }
  if (params?.satisfaction) {
    searchParams.set("satisfaction", params?.satisfaction);
  }
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/companies?${searchParams.toString()}`;
}

export function FeatureFeedbackUrl(
  env: UrlArgs,
  feature: UrlArgs,
  params?: { satisfaction?: SatisfactionSpan; subsegment?: string },
): string {
  // todo: add filtering and pagination using query params
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features/${slug(
    feature,
  )}/feedback?${searchParams.toString()}`;
}

export function FeaturesUrl(
  env: UrlArgs,
  params?: { subsegment?: string | string[] },
): string {
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/features?${searchParams.toString()}`;
}

export function FeatureViewUrl(env: UrlArgs, featureViewId: string): string {
  const searchParams = cleanQueryParams({ view: featureViewId });
  return `${EnvironmentUrl(env)}/features?${searchParams.toString()}`;
}

export function FeatureCompanyUrl(
  env: UrlArgs,
  companyId: string,
  featureId: string,
): string {
  const searchParams = cleanQueryParams({ featureFilter: featureId });
  return `${CompanyUrl(env, companyId)}/features?${searchParams.toString()}`;
}

export function CompanyUrl(env: UrlArgs, companyId: string): string {
  return `${EnvironmentUrl(env)}/companies/${encodeURIComponent(companyId)}`;
}

export function CompaniesUrl(
  env: UrlArgs,
  params?: { filter?: UIFilter; segmentId?: string },
): string {
  const searchParams = cleanQueryParams({
    filter: params?.filter ? serializeFilterParam(params?.filter) : undefined,
    segment: params?.segmentId,
  });
  return `${EnvironmentUrl(env)}/companies?${searchParams.toString()}`;
}

export function SegmentUrl(
  env: UrlArgs,
  segmentId: string,
  filter?: UIFilter,
): string {
  const searchParams = cleanQueryParams({
    segment: segmentId,
    filter: filter ? serializeFilterParam(filter) : undefined,
  });
  return `${EnvironmentUrl(env)}/companies?${searchParams.toString()}`;
}

export function FeedbacksUrl(
  env: UrlArgs,
  params?: {
    funnelStep?: FunnelStep[];
    satisfaction?: SatisfactionSpan;
    featureFilter?: string;
    companyFilter?: string;
  },
): string {
  // todo: add filtering and pagination using query params
  const searchParams = cleanQueryParams(params);
  return `${EnvironmentUrl(env)}/feedback?${searchParams.toString()}`;
}

export type GlobalSettingsSections =
  | "org-general"
  | "org-team"
  | "org-slack"
  | "org-billing"
  | "app-general"
  | "app-tracking"
  | "app-feature-views"
  | "app-segments"
  | "app-environments"
  | "app-slack"
  | "app-data-export"
  | "app-feedback";

export type FeatureSettingsSections =
  | "general"
  | "usage"
  | "feedback"
  | "slack";

export function GlobalSettingsUrl(
  env: UrlArgs,
  section?: GlobalSettingsSections,
): string {
  return `${EnvironmentUrl(env)}/settings${section ? `/${section}` : ""}`;
}

export function SalesEmail() {
  return "hello@bucket.co";
}

export function DataExportUrl(
  apiBaseUrl: string,
  appId: string,
  envId: string,
  interval: CsvExportIntervalType = "current",
) {
  const baseUrl = `${apiBaseUrl}/apps/${appId}/export/companies-features-usage.csv`;
  const params = new URLSearchParams({ interval, envId });

  return `${baseUrl}?${params.toString()}`;
}

export function AdminUrl(orgId?: string) {
  if (orgId) {
    return `/admin/${orgId}`;
  }

  return `/admin`;
}

export function FlagUrl(env: UrlArgs, flagId: string): string {
  return `${EnvironmentUrl(env)}/flags/${flagId}`;
}
