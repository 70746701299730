import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  AppDTO,
  PatchAppArgsSchema,
  PatchAppArgsType,
} from "@bucketco/shared/appAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function GeneralApp() {
  const { currentApp, currentOrg } = useAuthContext();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: PatchAppArgsType) =>
      api
        .patch<"/apps/:appId">(`/apps/${currentApp?.id}`, data)
        .then((res) => res.data.app),
    PatchAppArgsSchema,
    {
      onSuccess: (data) => {
        segmentAnalytics.track("App Options Updated");
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });
        form.reset({ name: data.name });
      },
    },
    {
      defaultValues: {
        name: currentApp?.name,
      },
    },
  );

  const confirmDisclosure = useDisclosure();
  const canDeleteApp = currentOrg && currentOrg.apps.length > 1;

  return (
    <VStack align="flex-start" spacing={8}>
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <VStack align="flex-start" maxW="compactForm" spacing={4}>
            <FormInput autoComplete="off" label="App name" name="name" />

            <FormRootError />

            <ButtonGroup>
              <FormSubmitLegacy />
              <FormReset />
            </ButtonGroup>
          </VStack>
        </FormProvider>
      </form>

      <Divider />

      <VStack align="flex-start" spacing={4}>
        <Heading as="h2" size="sm">
          Danger zone
        </Heading>
        <Tooltip
          isDisabled={canDeleteApp}
          label="The last app in the organization cannot be deleted"
        >
          <Button
            colorScheme="red"
            isDisabled={confirmDisclosure.isOpen || !canDeleteApp}
            loadingText="Deleting..."
            type="button"
            variant="solid"
            onClick={confirmDisclosure.onOpen}
          >
            Delete App
          </Button>
        </Tooltip>
        <ConfirmAppDeleteDialog
          app={currentApp!}
          disclosure={confirmDisclosure}
        />
      </VStack>
    </VStack>
  );
}

function ConfirmAppDeleteDialog({
  app,
  disclosure,
}: {
  app: AppDTO;
  disclosure: ReturnType<typeof useDisclosure>;
}) {
  const errorToast = useErrorToast();
  const { clearActiveApp } = useAuthContext();

  const deleteMutation = useMutation({
    mutationKey: ["deleteApp", app.id],
    mutationFn: () => api.delete<"/apps/:appId">(`/apps/${app.id}`),
    onSuccess: () => {
      clearActiveApp();
      window.location.href = "/";
    },
    onError: (error: any) => {
      errorToast({
        title: `Failed to delete '${app.name}' app`,
        description: error.response?.data.error.message,
      });

      form.reset();
    },
  });

  const form = useForm<{ name: string }>({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
    resolver: zodResolver(
      z.object({
        name: z.literal(app.name, {
          errorMap: () => ({ message: "Please confirm the app name" }),
        }),
      }),
    ),
  });

  function handleClose() {
    disclosure.onClose();
    form.reset();
  }

  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Delete App</AlertDialogHeader>
          <AlertDialogBody align="flex-start" as={VStack} spacing={4}>
            <Text as="p">
              Are you sure you want to delete <strong>{app.name}</strong>?
            </Text>

            <Alert alignItems="flex-start" status="warning">
              <AlertIcon />

              <VStack alignItems="flex-start" spacing={4}>
                <Text as="p">
                  All tracked events, users and companies on the apps tracking
                  key will be deleted.
                </Text>

                <Text as="p">You can&apos;t undo this action afterwards.</Text>
              </VStack>
            </Alert>

            <Text as="p">
              Please type <strong>{app.name}</strong> to confirm deletion.
            </Text>

            <form
              id="app-delete-modal"
              style={{
                width: "100%",
              }}
              onSubmit={form.handleSubmit(() => deleteMutation.mutateAsync())}
            >
              <FormProvider {...form}>
                <FormInput
                  autoComplete="off"
                  name="name"
                  width="100%"
                  data-1p-ignore
                ></FormInput>
              </FormProvider>
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                ref={cancelRef}
                _hover={{
                  color: useColorModeValue("gray.700", "gray.300"),
                }}
                color="gray.500"
                isDisabled={form.formState.isSubmitting}
                variant="ghost"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                form="app-delete-modal"
                isLoading={form.formState.isSubmitting}
                type="submit"
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
