import equal from "fast-deep-equal";

import { ColumnSort, ColumnState } from "@bucketco/shared/types/columns";

import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import {
  deserializeColumnState,
  deserializeSorting,
  serializeColumnState,
  serializeSorting,
} from "@/common/utils/datatable";

export const useDataTableParamState = <
  TSortBy extends string = string,
  TColumn extends string = string,
>({
  searchQuery: initialSearchQuery = "",
  pageIndex: initialPageIndex = 0,
  sorting: initialSortBy = [],
  columnStates: initialColumnStates = [],
}: {
  searchQuery?: string;
  pageIndex?: number;
  sorting?: ColumnSort<TSortBy>[];
  columnStates?: ColumnState<TColumn>[];
}) => {
  const [searchQuery, setSearchQuery] = useSearchParam("search", {
    fallback: initialSearchQuery,
  });

  const [pageIndex, setPageIndex] = useSearchParam("page", {
    fallback: initialPageIndex,
    deserialize: (value) => {
      const parsed = Number(value);
      return Number.isNaN(parsed) ? 0 : parsed;
    },
  });

  const [sorting, setSorting] = useSearchArrayParam<ColumnSort<TSortBy>>(
    "sorting",
    {
      fallback: initialSortBy,
      serialize: serializeSorting,
      deserialize: deserializeSorting,
    },
  );

  const [columnStates, setColumnStates] = useSearchArrayParam<
    ColumnState<TColumn>
  >("column", {
    fallback: initialColumnStates,
    serialize: serializeColumnState,
    deserialize: deserializeColumnState,
  });

  return {
    searchQuery,
    onSearchQueryChange: (query: string) => {
      setSearchQuery(query && query !== initialSearchQuery ? query : undefined);
    },

    pageIndex,
    onPageIndexChange: (index: number) => {
      setPageIndex(
        index >= 1 && index !== initialPageIndex ? index : undefined,
      );
    },

    sorting,
    onSortingChange: (sorting: ColumnSort<TSortBy>[]) => {
      setSorting(!equal(sorting, initialSortBy) ? sorting : []);
    },

    columnStates,
    onColumnStatesChange: (states: ColumnState<TColumn>[]) => {
      setColumnStates(!equal(states, initialColumnStates) ? states : []);
    },
  };
};
