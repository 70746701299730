import { useMemo } from "react";
import { Link, To } from "react-router-dom";
import {
  chakra,
  Divider,
  HStack,
  Spinner,
  StackProps,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureRolloutStatus } from "@bucketco/shared/featureAPI";

import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import dayjs from "@/common/utils/dayjs";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { RulesSummary } from "@/feature/components/RolloutStatus";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";

const EnvironmentIcon = chakra(EnvironmentSvg);

type RolloutStatusLabelProps = {
  status?: FeatureRolloutStatus;
  showRules?: boolean;
};

export const RolloutStatusLabel = ({
  status,
  showRules = true,
}: RolloutStatusLabelProps) => {
  const dividerColor = useColorModeValue("gray.300", "gray.600");

  if (!status) {
    return (
      <TagLabel alignItems="center" display="inline-flex" gap={1}>
        <NoDataYet />
      </TagLabel>
    );
  }

  return (
    <TagLabel alignItems="center" display="inline-flex" gap={2}>
      <EnvironmentDisplayName as="span" environment={status.environment} />
      {showRules && (
        <>
          <Divider
            alignSelf="stretch"
            borderColor={dividerColor}
            h="auto"
            orientation="vertical"
          />
          <RulesSummary rules={status.targetingRules} />
        </>
      )}
    </TagLabel>
  );
};

type RolloutStatusTagProps = {
  featureId?: string;
  link?: To;
};

export const RolloutStatusTag = ({
  featureId,
  link,
}: RolloutStatusTagProps) => {
  const { data, isLoading } = useFeatureRolloutStatusData(featureId);

  const status = useMemo(() => {
    return data?.find(
      ({ latestCheck, latestUsage }) =>
        dayjs().isAfter(latestCheck) || dayjs().isAfter(latestUsage),
    );
  }, [data]);

  if (isLoading) {
    return <Spinner color="dimmed" size="sm" />;
  }

  return (
    <Tag
      as={link ? Link : undefined}
      borderRadius="full"
      size="sm"
      to={link}
      variant="clickable"
    >
      <RolloutStatusLabel status={status} />
    </Tag>
  );
};

export function NoDataYet(props: StackProps) {
  const iconColor = useColorModeValue("gray.400", "gray.500");
  return (
    <HStack {...props} spacing={1}>
      <EnvironmentIcon boxSize={3} color={iconColor} />
      <Text as="span" color="dimmed" fontWeight="medium">
        No data yet
      </Text>
    </HStack>
  );
}
