import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getFilterCount } from "@bucketco/shared/filter";
import {
  CreateSegmentFormSchema,
  CreateSegmentFormType,
  PatchSegmentArgsSchema,
  PatchSegmentArgsType,
  SegmentDTO,
} from "@bucketco/shared/segmentAPI";
import { CompaniesUrl, SegmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AffectedDependencies from "@/common/components/AffectedDependencies";
import {
  DeleteIconButton,
  EditIconButton,
  GoToIconButton,
} from "@/common/components/CommonIconButtons";
import FloatingFormError from "@/common/components/Form/FloatingFormError";
import FormCancel from "@/common/components/Form/FormCancel";
import FormInput from "@/common/components/Form/FormInput";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useCanDeleteSegment } from "@/common/data/useFilterCheck";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import segmentsQueryKeys from "@/company/data/segmentsQueryKeys";
import { useSegments } from "@/company/data/useSegments";
import { useSegmentParam } from "@/company/hooks/useSegmentParam";
import { CreateAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import {
  ListLayout,
  ListLayoutProps,
} from "@/global-settings/components/GlobalSettingsHelpers";

const Layout = (props: ListLayoutProps) => (
  <ListLayout templateColumns="1fr 1fr" {...props} />
);

function Item({ segment }: { segment: SegmentDTO }) {
  const { appId, envId } = useCurrentEnv();

  const toast = useToast();
  const queryClient = useQueryClient();
  const { currentEnv } = useAuthContext();

  const { form, handleSubmit } = useApiForm(
    (data: PatchSegmentArgsType) =>
      api
        .patch<"/apps/:appId/segments/:segmentId">(
          `/apps/${appId}/segments/${segment.id}`,
          data,
        )
        .then((res) => res.data),
    PatchSegmentArgsSchema,
    {
      onSuccess: async ({ name }) => {
        segmentAnalytics.track("Segment Updated", {
          filters_count: getFilterCount(segment.companyFilter) - 1, // subtract the default $last_seen filter which is built-in
        });
        await queryClient.invalidateQueries({
          queryKey: segmentsQueryKeys.list(appId, envId),
        });
        toast({
          title: "Segment updated",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: name,
        });
        setShowEditForm(false);
      },
    },
    {
      defaultValues: {
        name: segment.name,
      },
    },
  );

  const [showEditForm, setShowEditForm] = useState(false);

  return !showEditForm ? (
    <Layout key={segment.id} showAsCard>
      <HStack>
        <Text isTruncated>{segment.name}</Text>
        {segment.system && (
          <InfoIconTooltip text="This is a built-in segment and cannot be renamed or deleted" />
        )}
      </HStack>
      <Box justifySelf="end">
        {!segment.system && (
          <>
            <EditIconButton
              label="Edit segment"
              onClick={() => setShowEditForm(true)}
            />
            <DeleteSegment segment={segment} />
          </>
        )}
        <GoToIconButton
          label="Open segment & edit filters"
          to={
            segment.isAllSegment
              ? CompaniesUrl(currentEnv!)
              : SegmentUrl(currentEnv!, segment.id)
          }
        />
      </Box>
    </Layout>
  ) : (
    <FormProvider {...form}>
      <SegmentForm
        submitLabel="Save"
        onClose={() => {
          setShowEditForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

function DeleteSegment({ segment }: { segment: SegmentDTO }) {
  const { appId, envId } = useCurrentEnv();
  const [storedSegmentId, setStoredSegmentId] = useSegmentParam();

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { mutate: deleteSegment, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/segments/:segmentId">(
        `/apps/${appId}/segments/${segment.id}`,
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Segment Deleted");
      if (storedSegmentId === segment.id) {
        setStoredSegmentId(null);
      }
      await queryClient.invalidateQueries({
        queryKey: segmentsQueryKeys.list(appId, envId),
      });

      toast({
        title: "Deleted segment",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete segment",
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { canDelete, isLoading: segmentCheckLoading } = useCanDeleteSegment(
    isOpen ? segment.id : undefined,
  );

  const cancelRef = useRef(null);

  return (
    <>
      <DeleteIconButton
        isDisabled={isDeleteLoading}
        label="Delete segment"
        onClick={onOpen}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete segment</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{segment.name}</strong> ?
              <br />
              You can&apos;t undo this action afterwards.
              {isOpen && (
                <AffectedDependencies
                  changeType="delete"
                  dependee={{
                    type: "segment",
                    id: segment.id,
                  }}
                  showAffectEnvironments
                />
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isDisabled={!canDelete}
                  isLoading={isDeleteLoading || segmentCheckLoading}
                  onClick={() => deleteSegment()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function SegmentsList() {
  const { data, isLoading } = useSegments();

  const segments = useMemo(() => {
    return data?.sort((a, b) => {
      if (a.isAllSegment) return -1;
      if (b.isAllSegment) return 1;

      return a.name.localeCompare(b.name);
    });
  }, [data]);

  if (isLoading) return null;

  return (
    <>
      <Layout
        color="dimmed"
        fontSize="sm"
        fontWeight="medium"
        minH="auto"
        py={1}
      >
        <Box>Name</Box>
      </Layout>
      {segments?.map((segment) => <Item key={segment.id} segment={segment} />)}
    </>
  );
}

function SegmentForm({
  onClose,
  onSubmit,
  submitLabel,
}: {
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
}) {
  return (
    <form onSubmit={onSubmit}>
      <Layout py={4} showAsCard>
        <Box>
          <FloatingFormError name="name">
            <Box>
              <FormInput
                autoComplete="off"
                name="name"
                placeholder="Name your segment"
                showErrors={false}
                autoFocus
                data-1p-ignore
              />
            </Box>
          </FloatingFormError>
        </Box>
        <Box justifySelf="end">
          <FormRootError />
          <ButtonGroup>
            <FormCancel onClick={() => onClose()} />
            <FormSubmitLegacy>{submitLabel}</FormSubmitLegacy>
          </ButtonGroup>
        </Box>
      </Layout>
    </form>
  );
}

const DEFAULT_FILTER = {
  type: "group" as const,
  operator: "and" as const,
  filters: [
    {
      type: "companyAttribute" as const,
      field: "$last_seen",
      operator: "AFTER" as const,
      values: ["30"],
    },
  ],
};

function CreateSegment() {
  const { appId, envId } = useCurrentEnv();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: CreateSegmentFormType) =>
      api
        .post<"/apps/:appId/segments">(`/apps/${appId}/segments`, {
          name: data.name || "",
          companyFilter: data.companyFilter || DEFAULT_FILTER,
          columns: data.columns,
        })
        .then((res) => res.data),
    CreateSegmentFormSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Segment Created");
        await queryClient.invalidateQueries({
          queryKey: segmentsQueryKeys.list(appId, envId),
        });
        toast({
          title: "Segment created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: "",
          companyFilter: DEFAULT_FILTER,
        });
        setShowCreateForm(false);
      },
    },
    {
      defaultValues: {
        name: "",
        companyFilter: DEFAULT_FILTER,
      },
    },
  );

  const [showCreateForm, setShowCreateForm] = useState(false);

  return !showCreateForm ? (
    <Layout px={0}>
      <Box>
        <Button variant="outline" onClick={() => setShowCreateForm((c) => !c)}>
          Add segment
        </Button>
      </Box>
    </Layout>
  ) : (
    <FormProvider {...form}>
      <SegmentForm
        submitLabel="Create"
        onClose={() => {
          setShowCreateForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

export default function Segments() {
  useEffect(() => {
    segmentAnalytics.page("App Segment Settings");
  }, []);

  return (
    <Flex direction="column" gap={2} maxW="wideForm">
      <CreateAffectEnvironments entity="segments" mb={4} />
      <SegmentsList />
      <CreateSegment />
    </Flex>
  );
}
