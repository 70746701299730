import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { buttonSizes, focusResetStyle } from "@/theme/helpers";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const InputTheme = defineMultiStyleConfig({
  defaultProps: {
    size: "sm",
  },
  baseStyle: {
    field: {
      bg: "appBackground",
      "&[type=search]::-webkit-search-cancel-button": {
        appearance: "none",
        height: "16px",
        width: "16px",
        // HACK: The prefixed block comment is to avoid Chakra messing with the svg. See https://github.com/chakra-ui/chakra-ui/issues/7548#issuecomment-1684034030
        backgroundImage:
          "/**/url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>\")",
        cursor: "pointer",
      },
      "&[type=search][value='']::-webkit-search-cancel-button": {
        appearance: "none",
        height: "0px",
        width: "0px",
        backgroundImage: "none",
        cursor: "default",
      },
    },
  },
  variants: {
    outline: (props) => ({
      field: {
        bg: mode("white", "gray.900")(props),
        borderColor: mode("gray.250", "gray.650")(props),
        boxShadow: "sm",
        _focus: focusResetStyle,
        _hover: {
          borderColor: mode("gray.350", "gray.600")(props),
        },
      },
    }),
  },
  sizes: {
    xs: {
      field: {
        ...buttonSizes.xs,
      },
    },
    sm: {
      field: {
        ...buttonSizes.sm,
        borderRadius: "md",
      },
    },
    md: {
      field: {
        ...buttonSizes.md,
      },
    },
    lg: {
      field: {
        ...buttonSizes.lg,
      },
    },
  },
});
