import { FocusEventHandler, useCallback, useRef, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import { useTextWidth } from "@tag0/use-text-width";

export default function SearchInput({
  value,
  placeholder = "Search by name/ID",
  onFocus,
  onBlur,
  ...inputProps
}: InputProps) {
  const searchIconColor = useColorModeValue("gray.400", "gray.600");
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (...args) => {
      setIsFocused(true);
      onFocus?.(...args);
    },
    [onFocus],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (...args) => {
      setIsFocused(false);
      onBlur?.(...args);
    },
    [onBlur],
  );

  const fontSize = useToken("fontSizes", "sm");
  const fontFamily = useToken("fonts", "body");
  const expandedWidth =
    useTextWidth({
      text: placeholder,
      font: `${fontSize} ${fontFamily}`,
    }) + 48;

  return (
    <InputGroup>
      <InputLeftElement
        color={!value ? searchIconColor : undefined}
        cursor="text"
        onClick={() => inputRef.current?.focus()}
      >
        <RiSearchLine size={16} />
      </InputLeftElement>
      <Input
        ref={inputRef}
        _focus={{
          width: `${expandedWidth}px`,
        }}
        placeholder={!isFocused ? "Search" : placeholder}
        size="sm"
        transition="width 120ms ease-in-out"
        type="search"
        value={value}
        width={value ? `${expandedWidth}px` : "96px"}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...inputProps}
      />
    </InputGroup>
  );
}
