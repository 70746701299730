import { useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { z } from "zod";

import {
  CompanyUserDTO,
  CompanyUsersQuerySortBySchema,
  FeatureCompanyUserDTO,
  FeatureUsersQuerySortBySchema,
} from "@bucketco/shared/companyAPI";

import EmptyStateTriedDark from "@/common/assets/empty-state-companies-tried-dark.svg?react";
import EmptyStateTriedLight from "@/common/assets/empty-state-companies-tried-light.svg?react";
import CompactNumber from "@/common/components/CompactNumber";
import DataTable from "@/common/components/DataTableLegacy";
import EmptyState from "@/common/components/EmptyState";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TablePagination from "@/common/components/TablePagination";
import TimestampCell from "@/common/components/TimestampCell";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { useCompanyContext } from "@/company/contexts/CompanyContext";

export default function CompanyUsers() {
  const { usersDataTables: tables, clearFilters } = useCompanyContext();
  const [featureFilter] = useSearchParam("featureFilter");

  const emptyIllustration = useColorModeValue(
    <EmptyStateTriedLight />,
    <EmptyStateTriedDark />,
  );

  const table = featureFilter
    ? tables?.featureCompanyUsers
    : tables?.companyUsers;

  if (!table || table.isLoading) {
    return (
      <Box px={6} py={4}>
        <Spinner size="sm" />
      </Box>
    );
  }

  return (
    <Flex direction="column" flexGrow={1} width="100%">
      <Flex justify="end" px={6} py={3.5}>
        <TablePagination
          canPaginate={table.canPaginate}
          label="Entries"
          pageCount={table.pageCount}
          pageIndex={table.data?.pageIndex}
          pageSize={table.data?.pageSize}
          paginateActions={table.paginateActions}
          totalCount={table.data?.totalCount}
        />
      </Flex>
      <Divider />
      {table.data?.data.length === 0 ? (
        <EmptyState
          description={
            <VStack>
              <Text>No users to show</Text>
              {!table.isLoading && featureFilter && (
                <Button
                  color="gray.500"
                  fontSize="sm"
                  variant="link"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear filters
                </Button>
              )}
            </VStack>
          }
          flexGrow={1}
          pt="24"
        >
          {emptyIllustration}
        </EmptyState>
      ) : featureFilter ? (
        <FeatureUsers />
      ) : (
        <AllUsers />
      )}
    </Flex>
  );
}

function AllUsers() {
  const { usersDataTables: tables } = useCompanyContext();
  const table = tables?.companyUsers;

  const columnHelper = createSortableColumnHelper<
    CompanyUserDTO,
    z.infer<typeof CompanyUsersQuerySortBySchema>
  >();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",

        cell: ({ row }) => <Text>{row.original.name || row.original.id}</Text>,
      }),
      columnHelper.accessor("firstSeen", {
        header: "First seen",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("lastSeen", {
        header: "Last seen",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("eventCount", {
        header: "Event count",
        cell: (cell) => <CompactNumber value={cell.getValue()} />,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <Link href={`mailto:${value}`}>{value}</Link>;
          }

          return <NotAvailableCell />;
        },
      }),
    ];
  }, [columnHelper]);

  const tableConfiguration = useLocalStorageTableConfiguration("CompanyUsers", {
    defaultColumns: [],
    defaultSort: {
      id: "lastSeen",
      desc: true,
    },
  });

  if (!table) return null;

  return (
    <Flex as="section" direction="column" flexGrow={1} gap={2}>
      <DataTable
        columns={columns}
        columnStates={tableConfiguration.columns}
        data={table.data?.data || []}
        fetchData={table.fetchData}
        isFetching={table.isFetching}
        pageCount={table.pageCount}
        setCanPaginate={table.setCanPaginate}
        setColumnStates={tableConfiguration.setColumns}
        setPaginateActions={table.setPaginateActions}
        setSortBy={tableConfiguration.setSort}
        sortBy={tableConfiguration.sort}
        trackingId="company-users"
      />
    </Flex>
  );
}

function FeatureUsers() {
  const { usersDataTables: tables } = useCompanyContext();
  const table = tables?.featureCompanyUsers;

  const columnHelper = createSortableColumnHelper<
    FeatureCompanyUserDTO,
    z.infer<typeof FeatureUsersQuerySortBySchema>
  >();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => <Text>{row.original.name || row.original.id}</Text>,
      }),
      columnHelper.accessor("firstUsed", {
        header: "First used",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("lastUsed", {
        header: "Last used",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("eventCount", {
        header: "Feature interactions",
        cell: (cell) => <CompactNumber value={cell.getValue()} />,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <Link href={`mailto:${value}`}>{value}</Link>;
          }

          return <NotAvailableCell />;
        },
      }),
    ];
  }, [columnHelper]);

  const tableConfiguration = useLocalStorageTableConfiguration(
    "CompanyFeatureUsers",
    {
      defaultColumns: [],
      defaultSort: {
        id: "lastUsed",
        desc: true,
      },
    },
  );

  if (!table) return null;

  return (
    <Flex as="section" direction="column" flexGrow={1} gap={2}>
      <DataTable
        columns={columns}
        columnStates={tableConfiguration.columns}
        data={table.data?.data || []}
        fetchData={table.fetchData}
        isFetching={table.isFetching}
        pageCount={table.pageCount}
        setCanPaginate={table.setCanPaginate}
        setColumnStates={tableConfiguration.setColumns}
        setPaginateActions={table.setPaginateActions}
        setSortBy={tableConfiguration.setSort}
        sortBy={tableConfiguration.sort}
        trackingId="feature-users"
      />
    </Flex>
  );
}
