import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, HStack, Tag } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { FeatureGoal, FunnelStep } from "@bucketco/shared/featureAPI";
import { buildFeatureFunnelFilters } from "@bucketco/shared/filter";
import { CompaniesUrl, FeedbacksUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";
import {
  WidgetFeatureMetricConfiguration,
  WidgetFeatureMetricConfigurationSchema,
  WidgetFeatureMetrics,
} from "@bucketco/shared/widgetAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { Period, periodList } from "@/common/components/PeriodPicker";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import useAllSegment from "@/company/data/useAllSegment";
import { FeatureMetricIcon } from "@/feature/components/FeatureWidgetCreator";
import { GoalDescription } from "@/feature/components/Goals/GoalDescription";
import { GoalStatusIcon } from "@/feature/components/Goals/GoalStatusIcon";
import { useFeatureHistoricalData } from "@/feature/data/useFeatureHistoricalData";
import {
  getGoalStatus,
  useGoalStatusColors,
} from "@/feature/hooks/useGoalStatusColors";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";
import { BaseWidgetProps } from "@/widget/components/types";
import { WidgetChart } from "@/widget/components/WidgetFeatureMetric/WidgetChart";
import { WidgetState } from "@/widget/components/WidgetFeatureMetric/WidgetState";
import { WidgetLayout } from "@/widget/components/WidgetLayout";
import { useWidgetUpdateMutation } from "@/widget/data/useWidgetMutations";
import { formatWidgetValue } from "@/widget/utils/format";
import { getCurrentValue, periodToDates } from "@/widget/utils/widget";

import { WidgetForm } from "./WidgetForm";

type Props = BaseWidgetProps<WidgetFeatureMetricConfiguration>;

const WIDGET_HEIGHT = 250;

export function WidgetFeatureMetric({
  widgetId,
  configuration,
  implicitConfiguration,
}: Props) {
  const { currentEnv } = useAuthContext();
  const [isEditing, setIsEditing] = useState(false);
  const [subsegment] = useSubsegmentParam(["Active"]);
  const [useTargeting] = useTargetingParam(true);
  const [period] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });
  const { startDate, endDate } = useMemo(() => periodToDates(period), [period]);

  const updateMutation = useWidgetUpdateMutation(widgetId);

  const form = useForm<WidgetFeatureMetricConfiguration>({
    resolver: zodResolver(WidgetFeatureMetricConfigurationSchema),
    mode: "onChange",
    defaultValues: {
      threshold: WidgetFeatureMetrics["adopted"].defaultThreshold,
      ...implicitConfiguration,
      ...configuration,
    },
  });

  const values = form.watch();

  const currentConfiguration = useMemo(
    () => (isEditing ? values : configuration),
    [configuration, isEditing, values],
  );

  const { data, status, isFetching } = useFeatureHistoricalData(
    currentConfiguration.featureId,
    {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      metric: currentConfiguration.metric,
      subsegment: subsegment[0],
      useTargetingRules: useTargeting,
    },
  );

  const currentValue = useMemo(() => {
    return getCurrentValue(data?.timeseries ?? []);
  }, [data]);

  const [subsegments] = useSubsegmentParam(["Active"]);
  const allSegment = useAllSegment();

  const filter = useMemo(() => {
    let step: FunnelStep | "satisfied";

    switch (currentConfiguration.metric) {
      case "tried":
      case "triedCount":
        step = "tried";
        break;
      case "adopted":
      case "adoptedCount":
        step = "adopted";
        break;
      case "retained":
      case "retainedCount":
        step = "retained";
        break;
      case "satisfied":
      case "satisfiedCount":
        step = "satisfied";
        break;
      default:
        return undefined;
    }

    const [, filter] = buildFeatureFunnelFilters(
      step,
      currentConfiguration.featureId,
      useTargeting,
      subsegments,
    );

    return filter;
  }, [currentConfiguration, useTargeting, subsegments]);

  const linkUrl = useMemo(() => {
    if (currentConfiguration.metric === "feedbackCount") {
      return FeedbacksUrl(currentEnv!, {
        featureFilter: currentConfiguration.featureId,
      });
    }

    if (filter) {
      return CompaniesUrl(currentEnv!, {
        filter,
        segmentId: allSegment?.id,
      });
    }

    return undefined;
  }, [filter, currentConfiguration, allSegment, currentEnv]);

  const definition = WidgetFeatureMetrics[currentConfiguration.metric];
  const isPercentage = definition.type === "percentage";

  // TODO do I really have to construct all of this manually?
  const hasThreshold = currentConfiguration?.threshold ?? 0 > 0;
  const goal: FeatureGoal | undefined = hasThreshold
    ? {
        currentValue: currentValue ?? 0,
        threshold: currentConfiguration.threshold ?? 0,
        widgetId,
        featureId: currentConfiguration.featureId,
        metric: currentConfiguration.metric,
      }
    : undefined;

  const goalStatus = goal ? getGoalStatus(goal) : undefined;
  const goalColor = useGoalStatusColors(goalStatus);

  return (
    <WidgetLayout
      form={
        <WidgetForm
          configuration={currentConfiguration}
          form={form}
          implicitConfiguration={implicitConfiguration}
          widgetMutation={updateMutation}
          onDone={(configuration) => {
            form.reset(configuration);
            setIsEditing(false);
          }}
        />
      }
      icon={<FeatureMetricIcon boxSize={4} color="dimmedDisabled" />}
      isEditing={isEditing}
      isLoading={isFetching}
      setIsEditing={(newIsEditing) => {
        if (!newIsEditing) form.reset(configuration);
        setIsEditing(newIsEditing);
      }}
      title={
        <HStack spacing={3}>
          <SectionHeading>{definition?.label}</SectionHeading>
          {goal ? (
            <Tag
              bg={goalColor.bg}
              fontWeight="medium"
              gap={2}
              size="xs"
              variant="subtle"
            >
              <GoalStatusIcon
                progress={getFraction(
                  currentValue,
                  currentConfiguration.threshold,
                )}
                showPercentage={false}
                size={16}
                status={getGoalStatus(goal)}
              />
              <GoalDescription
                goal={goal}
                showLabel={false}
                colored
                showThreshold
              />
            </Tag>
          ) : (
            <Tag fontWeight="medium" size="xs" variant="subtle">
              {formatWidgetValue(
                currentValue ?? 0,
                isPercentage,
                configuration.metric,
              )}
            </Tag>
          )}
        </HStack>
      }
      toolbar={
        linkUrl && (
          <Button as={Link} size="xs" to={linkUrl} variant="outline">
            {currentConfiguration.metric === "feedbackCount"
              ? "See feedback"
              : "See companies"}
          </Button>
        )
      }
      widgetId={widgetId}
    >
      <WidgetState
        hasData={!!data?.timeseries.length}
        minH={`${WIDGET_HEIGHT}px`}
        status={status}
      >
        <WidgetChart
          data={data?.timeseries ?? []}
          endDate={endDate.toDate()}
          height={WIDGET_HEIGHT}
          isPercentage={
            WidgetFeatureMetrics[currentConfiguration.metric].type ===
            "percentage"
          }
          metric={currentConfiguration.metric}
          startDate={startDate.toDate()}
          threshold={currentConfiguration.threshold}
        />
      </WidgetState>
    </WidgetLayout>
  );
}
