import equal from "fast-deep-equal";

import {
  FeatureListColumn,
  FeatureListSortBy,
} from "@bucketco/shared/featureAPI";

import { useFeatureViewData } from "@/app/data/useFeatureViewData";
import { useDataTableParamState } from "@/common/hooks/useDataTableParamState";

export const useFeaturesTableState = (viewId?: string | null) => {
  // Load persisted sorting and column states from feature view.
  const { data: featureView, isLoading } = useFeatureViewData(viewId);
  const featureViewSorting = featureView?.columnSortKey
    ? [
        {
          id: featureView.columnSortKey,
          desc: featureView.columnSortDesc ?? true,
        },
      ]
    : [];
  const featureViewColumnStates = featureView ? featureView.columns : [];

  // Create table state from feature view data and url params.
  const tableState = useDataTableParamState<
    FeatureListSortBy,
    FeatureListColumn
  >({
    sorting: featureViewSorting,
    columnStates: featureViewColumnStates,
  });

  const isDirty =
    !equal(tableState.columnStates, featureViewColumnStates) ||
    !equal(tableState.sorting, featureViewSorting);

  return {
    ...tableState,
    isDirty,
    isLoading,
  };
};
