import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Flex, HStack, Td, Text, Tr } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { FeedbacksUrl } from "@bucketco/shared/urls";
import {
  WidgetFeedbackConfiguration,
  WidgetFeedbackConfigurationSchema,
} from "@bucketco/shared/widgetAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import SimpleTable from "@/common/components/SimpleTable";
import TimestampCell from "@/common/components/TimestampCell";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { FeatureFeedbackIcon } from "@/feature/components/FeatureWidgetCreator";
import SatisfactionHistogram from "@/feature/components/SatisfactionHistogram";
import { useFeatureFeedbackData } from "@/feature/data/useFeatureFeedbackData";
import SatisfactionCell from "@/feedback/components/SatisfactionCell";
import { BaseWidgetProps } from "@/widget/components/types";
import { WidgetForm } from "@/widget/components/WidgetFeedback/WidgetForm";
import { WidgetState } from "@/widget/components/WidgetFeedback/WidgetState";
import { WidgetLayout } from "@/widget/components/WidgetLayout";
import { useWidgetUpdateMutation } from "@/widget/data/useWidgetMutations";

type Props = BaseWidgetProps<WidgetFeedbackConfiguration>;
const WIDGET_HEIGHT = 220;

export const WidgetFeedback = ({
  widgetId,
  variant,
  configuration,
  implicitConfiguration,
}: Props) => {
  const { currentEnv } = useAuthContext();
  const [isEditing, setIsEditing] = useState(false);
  const [subsegment] = useSubsegmentParam();

  const updateMutation = useWidgetUpdateMutation(widgetId);

  const form = useForm<WidgetFeedbackConfiguration>({
    resolver: zodResolver(WidgetFeedbackConfigurationSchema),
    mode: "onChange",
    defaultValues: {
      ...implicitConfiguration,
      ...configuration,
    },
  });

  const values = form.watch();

  const currentConfiguration = useMemo(
    () => (isEditing ? values : configuration),
    [configuration, isEditing, values],
  );

  const { data, status, isFetching } = useFeatureFeedbackData(
    currentConfiguration.featureId,
  );
  const feedback = data?.data ?? [];

  return (
    <WidgetLayout
      canEdit={!implicitConfiguration?.featureId}
      form={
        <WidgetForm
          configuration={currentConfiguration}
          form={form}
          implicitConfiguration={implicitConfiguration}
          widgetMutation={updateMutation}
          onDone={(configuration) => {
            form.reset(configuration);
            setIsEditing(false);
          }}
        />
      }
      icon={<FeatureFeedbackIcon boxSize={4} color="dimmedDisabled" />}
      isEditing={isEditing}
      isLoading={isFetching}
      setIsEditing={(newIsEditing) => {
        if (!newIsEditing) form.reset(configuration);
        setIsEditing(newIsEditing);
      }}
      title="Feedback"
      toolbar={
        <HStack spacing={3}>
          <HStack spacing={1}>
            <Text color="dimmed" fontSize="xs">
              Showing all data
            </Text>
            <InfoIconTooltip text="This widget is not affected by the targeting rules, segment, or time period." />
          </HStack>
          <Button
            as={Link}
            size="xs"
            to={FeedbacksUrl(currentEnv!, {
              featureFilter: currentConfiguration.featureId,
            })}
            variant="outline"
          >
            See all responses
          </Button>
        </HStack>
      }
      widgetId={widgetId}
    >
      <WidgetState
        hasData={!!feedback.length}
        minH={`${WIDGET_HEIGHT}px`}
        status={status}
      >
        <Flex align="flex-start" gap={8} w="full">
          <Flex align="flex-start" direction="column" gap={4} m={1} w="full">
            <Text color="dimmed" fontSize="sm">
              Recent responses
            </Text>
            <SimpleTable
              border="1px solid"
              borderBottom="none"
              borderColor="appBorder"
              borderRadius="base"
              columns={[]}
              flexGrow={1}
              rows={feedback}
              rowTemplate={({
                id,
                score,
                comment,
                companyId,
                companyName,
                userName,
                timestamp,
              }) => (
                <Tr key={id} fontSize="sm">
                  <Td>
                    <HStack>
                      <SatisfactionCell value={score} />
                      <Text>
                        {comment?.slice(0, 80) ?? "No comment"}...
                      </Text>{" "}
                    </HStack>
                  </Td>
                  {variant === "full" && (
                    <>
                      <Td>
                        <CompanyDisplay
                          id={companyId!}
                          name={companyName}
                          link
                        />
                      </Td>
                      <Td>{userName}</Td>
                      <Td>
                        <TimestampCell value={timestamp} leftAlign />
                      </Td>
                    </>
                  )}
                </Tr>
              )}
              size="sm"
            />
          </Flex>
          <Flex
            direction="column"
            flexGrow={1}
            gap={5}
            maxW="xs"
            minW="2xs"
            pb={4}
            pr={4}
          >
            <Text color="dimmed" fontSize="sm">
              Satisfaction score distribution
            </Text>
            <SatisfactionHistogram
              featureId={currentConfiguration.featureId}
              subsegmentId={subsegment[0]}
            />
          </Flex>
        </Flex>
      </WidgetState>
    </WidgetLayout>
  );
};
