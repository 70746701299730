import { Center, Spinner } from "@chakra-ui/react";
import { useLocalStorage } from "usehooks-ts";

import {
  BucketProvider,
  Features,
  useFeature as useBucketFeature,
} from "@bucketco/react-sdk";

import { useAuthContext } from "@/auth/contexts/authContext";
import { API_URL, DOGFOOD_TRACKING_KEY, getEnvVar } from "@/common/utils/env";

// keep this up to date to achieve proper type safety
// when working with the targeting/flagging
const availableFeatures = [
  "allow-domain-auto-join",
  "data-table-rework",
] as const;

type ArrayToRecord<T extends readonly string[]> = {
  [Key in T[number]]: boolean;
};

export type AvailableFeatures = ArrayToRecord<typeof availableFeatures>;

declare module "@bucketco/react-sdk" {
  interface Features extends AvailableFeatures {}
}

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, currentOrg } = useAuthContext();

  let userCtx;
  let companyCtx;
  if (user?.id) {
    userCtx = {
      id: user.id,
      email: user.email,
    };
  }

  if (currentOrg?.id) {
    companyCtx = {
      id: currentOrg.id,
      accessLevel: currentOrg.accessLevel,
      createdAt: currentOrg.createdAt,
    };
  }

  // skip rendering the Bucket component if the tracking key is not set
  // or we're not logged in/bootstrapping
  if (!DOGFOOD_TRACKING_KEY || !user?.id) {
    return children;
  }

  return (
    <BucketProvider
      company={companyCtx}
      featureOptions={{
        staleWhileRevalidate: false,
      }}
      feedback={{
        enableLiveSatisfaction: false,
      }}
      host={`${API_URL}/ingest-proxy`}
      loadingComponent={
        <Center h="100vh">
          <Spinner size="sm" />
        </Center>
      }
      publishableKey={DOGFOOD_TRACKING_KEY}
      user={userCtx}
    >
      {children}
    </BucketProvider>
  );
}

function useLocalOverrideStorage() {
  return useLocalStorage<Record<string, boolean>>(
    "bucket-targeting-overrides",
    {},
  );
}

export function useFeatures() {
  const [localFeatures, setLocalFeatures] = useLocalOverrideStorage();

  const resetLocalOverides = () => {
    setLocalFeatures({});
  };

  const hasLocalOverrides = Object.keys(localFeatures).length > 0;

  return {
    availableFeatures,
    hasLocalOverrides,
    resetLocalOverides,
  };
}

export function useFeature(key: keyof Features) {
  const [localFeatures, setLocalFeatures] = useLocalOverrideStorage();

  const updateLocalOverride = (value: boolean | null) => {
    setLocalFeatures((features) => {
      if (value === null) {
        delete features[key];
      } else {
        features[key] = value;
      }
      return features;
    });
  };

  const { isEnabled: evaluation, isLoading, track } = useBucketFeature(key);

  const envVar = ENV_FEATURES[key] ?? null;
  const override = localFeatures[key] ?? null;

  return {
    isLoading,
    isEnabled: override ?? envVar ?? evaluation,
    values: {
      evaluation,
      envVar,
      override,
    },
    updateLocalOverride,
    track,
  };
}

const ENV_FEATURES = (
  getEnvVar("REACT_APP_FEATURES")
    ?.split(",")
    .map((flag) => flag.trim()) || []
).reduce(
  (acc, key) => {
    acc[key] = true;
    return acc;
  },
  {} as Record<string, boolean>,
);
