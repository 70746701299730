import { useMemo, useState } from "react";
import {
  Box,
  chakra,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from "recharts";

import { FeatureMetric } from "@bucketco/shared/featureAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";
import { WidgetFeatureMetrics } from "@bucketco/shared/widgetAPI";

import TargetSvg from "@/common/assets/target.svg?react";
import {
  IncompleteBarPattern,
  incompleteBarPatternId,
} from "@/common/charts/components/IncompleteBar";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import {
  epochIsSame,
  epochToDate,
  epochToShortDate,
} from "@/common/utils/datetime";
import { formatWidgetValue } from "@/widget/utils/format";

const TargetIcon = chakra(TargetSvg);

type Props = {
  data: { epoch: number; value: number | null }[];
  startDate: Date;
  endDate: Date;
  metric?: FeatureMetric;
  releasedAt?: Date;
  achievedAt?: Date;
  height?: number;
  threshold?: number | null;
  isPercentage?: boolean;
  highlightPartialPeriod?: boolean;
};

const LINE_ANIMATION_DURATION = 500;

export const WidgetChart = ({
  data,
  startDate,
  endDate,
  metric,
  threshold,
  height = 270,
  isPercentage,
  highlightPartialPeriod = true,
}: Props) => {
  const definition = metric && WidgetFeatureMetrics[metric];

  const [width, setWidth] = useState<number>(720);
  const lineColor = useToken(
    "colors",
    useColorModeValue("brand.400", "brand.400"),
  );
  const chartStroke = useToken(
    "colors",
    useColorModeValue("gray.200", "gray.650"),
  );
  const { colors, properties, radii, fontSizes, sizes } = useChartTokens();

  const endValue = useMemo(
    () =>
      endDate
        ? data.find((d) => d.epoch >= dayjs(endDate).unix())?.value ?? null
        : null,
    [data, endDate],
  );

  const isCurrentValueAboveThreshold = useMemo(
    () => threshold && endValue && endValue >= threshold,
    [endValue, threshold],
  );

  const thresholdColor = isCurrentValueAboveThreshold
    ? colors.active
    : colors.evaluating;

  const partialDataPeriod = useMemo<[number, number] | null>(() => {
    // Only show when enabled
    if (!highlightPartialPeriod) return null;

    const lastTwoEntries = data
      .filter(({ value }) => value !== null)
      .map(({ epoch }) => epoch)
      .reverse()
      .slice(0, 2);

    // Only show if there is enough data
    if (lastTwoEntries.length !== 2) return null;

    // Only show if the partial data period is today
    if (!epochIsSame(lastTwoEntries[0], "day")) return null;

    return lastTwoEntries as [number, number];
  }, [data, highlightPartialPeriod]);

  const now = dayjs();
  const tickCount = width
    ? Math.floor((width - sizes.yAxisWidth * 2) / 48) // 48px per tick
    : 0;
  const granularityDiff = dayjs(endDate).diff(startDate, "day");
  const nowDiff = now.diff(startDate, "day");
  const shift = nowDiff % 2; // always show now tick

  const domain = useMemo<[number, number]>(
    () =>
      data.length > 0
        ? [data[0].epoch, data[data.length - 1].epoch]
        : [dayjs(startDate).unix(), dayjs(endDate).unix()],
    [data, startDate, endDate],
  );

  const yAxis = useWidgetYAxis({ data, metric, threshold, isPercentage });

  return (
    <ResponsiveContainer
      height={height}
      width="100%"
      onResize={(newWidth) => {
        setWidth(newWidth);
      }}
    >
      <LineChart
        data={data}
        margin={{
          top: 12,
          right: 35,
          bottom: -16,
          left: 10,
        }}
      >
        {partialDataPeriod !== null && (
          <>
            <defs>
              <IncompleteBarPattern
                colorName={"partialHighlight"}
                colorValue={colors.partialHighlight}
              />
            </defs>
            <ReferenceArea
              fill={`url(#${incompleteBarPatternId("partialHighlight")})`}
              x1={partialDataPeriod[0]}
              x2={partialDataPeriod[1]}
              yAxisId="line"
            />
          </>
        )}
        <CartesianGrid
          stroke={chartStroke}
          strokeDasharray="2"
          vertical={false}
        />
        <XAxis
          axisLine={true}
          dataKey="epoch"
          domain={domain}
          dy={8}
          fontSize={fontSizes.axisLabel}
          height={50}
          interval={0}
          minTickGap={4}
          scale="utc"
          stroke={colors.axis}
          tickFormatter={(epoch: number, index: number) => {
            if (
              (index + shift) %
                Math.ceil(getFraction(granularityDiff, tickCount)) !==
              0
            )
              return "";
            if (epochIsSame(epoch, "day")) return "Now";
            return epochToShortDate(epoch);
          }}
          tickLine={true}
          type="number"
        />
        <YAxis
          axisLine={false}
          fontSize={fontSizes.axisLabel}
          stroke={colors.axis}
          tickFormatter={(value) =>
            formatWidgetValue(value, isPercentage, metric)
          }
          tickLine={false}
          tickMargin={4}
          type="number"
          width={sizes.yAxisWidth}
          yAxisId="line"
          {...yAxis}
        />
        {threshold && (
          <>
            <ReferenceLine
              ifOverflow="extendDomain"
              label={<AdoptionLineLabel color={thresholdColor} />}
              stroke={thresholdColor}
              strokeDasharray="2"
              y={threshold}
              yAxisId="line"
            />
          </>
        )}
        <Tooltip
          contentStyle={{
            borderColor: colors.tooltipBorder,
            backgroundColor: colors.tooltipBg,
            borderRadius: radii.tooltip,
            fontSize: fontSizes.tooltip,
          }}
          cursor={{ stroke: colors.cursor }}
          formatter={(value: number) => {
            return [
              formatWidgetValue(value, isPercentage, metric),
              definition?.label ?? "",
            ] as any; // workaround for https://github.com/recharts/recharts/issues/2976
          }}
          isAnimationActive={false}
          labelFormatter={(epoch: number) => {
            if (epochIsSame(epoch, "day")) {
              return (
                <>
                  <Text as="span">{dayjs().format("MMM D, LT")}</Text>
                  <br />
                  <Text as="span" color="dimmed">
                    (Partial day)
                  </Text>
                </>
              );
            }
            return epochToDate(epoch);
          }}
        />
        <Line
          activeDot={properties.activeDot}
          animationDuration={LINE_ANIMATION_DURATION}
          animationEasing="ease"
          dataKey="value"
          dot={false}
          stroke={lineColor}
          strokeLinecap="round"
          strokeWidth={3}
          type="monotone"
          yAxisId="line"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

function AdoptionLineLabel({
  color,
  viewBox = { width: 0, x: 0, y: 0 },
}: {
  color?: string;
  viewBox?: { width: number; x: number; y: number };
}) {
  const defaultColor = useColorModeValue("gray.500", "gray.500");
  return (
    <foreignObject
      height="100px"
      width="200px"
      x={viewBox.width + viewBox.x + 2}
      y={viewBox.y - 10}
    >
      <Box width="fit-content">
        <TargetIcon color={color ?? defaultColor} height="18px" width="18px" />
      </Box>
    </foreignObject>
  );
}

function useWidgetYAxis({
  data,
  metric,
  isPercentage,
  threshold,
}: {
  data: { epoch: number; value: number | null }[];
  metric?: FeatureMetric;
  isPercentage?: boolean;
  threshold?: number | null;
}): YAxisProps {
  return useMemo(() => {
    if (metric === "averageFrequency") {
      return {
        ticks: [0, 1, 2, 3],
        domain: [0, 3],
      };
    }

    const max = Math.max(threshold ?? 0, ...data.map((d) => d.value ?? 0));
    const formattedMax = isPercentage
      ? 1 // Ensure percentage caps at 100%
      : Math.max(5, max); // Ensure there are at least as many tick values as ticks

    const ticks = getTicks(formattedMax, 5, 5);
    const domain = [0, ticks[ticks.length - 1]];

    return {
      ticks,
      domain,
    };
  }, [data, metric, isPercentage, threshold]);
}

// Create neat looking chart ticks by ensuring each tick value is
// divisible by a given number
function getTicks(value: number, tickCount: number, tickDivisor: number) {
  const step = roundUpToNearest(value / (tickCount - 1), tickDivisor);
  return Array.from(new Array(tickCount)).map((_, i) => i * step);
}

// Round a number to the nearest value keeping at most 2 decimal places.
function roundUpToNearest(value: number, nearest: number) {
  if (value <= 1) {
    return Math.round(Math.ceil((100 * value) / nearest) * nearest) / 100;
  } else {
    return Math.round(Math.ceil(value / nearest) * nearest * 100) / 100;
  }
}
