import { createContext, useContext } from "react";
import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import {
  RiBracesLine,
  RiCalendarLine,
  RiCloseLine,
  RiFontSize2,
  RiGroupLine,
  RiRefreshLine,
} from "react-icons/ri";
import {
  Box,
  Button,
  chakra,
  FormControl,
  Grid,
  IconButton,
  InputProps,
  VStack,
} from "@chakra-ui/react";

import {
  AttributeField,
  AttributeFieldIcons,
  AttributeFilterOperator,
  AttributeFilterRuleType,
  defaultTypeValues,
  listOperators,
  relevantOperators,
  TypeByOperator,
} from "@bucketco/shared/attributeFilter";

import IdTagSvg from "@/common/assets/id-tag.svg?react";
import { AndOrList } from "@/common/components/AndOrList";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import RuleAttribute from "@/common/components/Rule/RuleAttribute";
import RuleOperator from "@/common/components/Rule/RuleOperator";
import RuleValue, {
  getFieldTypeFromAttributeType,
} from "@/common/components/Rule/RuleValue";
import { useFormFieldDiff } from "@/common/hooks/useFormFieldDiff";
import { useFormFieldUpdate } from "../hooks/useFormFieldUpdate";

const IdTagIcon = chakra(IdTagSvg);

export const fieldIcons: { [key in AttributeFieldIcons]: JSX.Element } = {
  id: <IdTagIcon w="13px" />,
  text: <RiFontSize2 />,
  calendar: <RiCalendarLine />,
  group: <RiGroupLine />,
  active: <RiRefreshLine />,
  custom: <RiBracesLine />,
};

export const defaultRuleTemplate = (
  field = "name",
): AttributeFilterRuleType => ({
  field,
  operator: "IS",
  values: [""],
});

export type RuleErrors = Partial<{
  [key in keyof AttributeFilterRuleType]: FieldError;
}>;

function RuleComponent({
  name,
  removeRule,
  allAttributes,
  canRemove = true,
  isDisabled = false,
}: {
  name: string;
  removeRule: () => void;
  allAttributes: AttributeField[];
  canRemove?: boolean;
  isDisabled?: boolean;
}) {
  const { size } = useContext<AttributeFilterContextType>(
    AttributeFilterContext,
  );

  const form = useFormContext<{
    [x: string]: AttributeFilterRuleType;
  }>();

  const ruleValue = form.watch(name);

  const fieldSelectorName: `${string}.field` = `${name}.field`;
  const operatorSelectorName: `${string}.operator` = `${name}.operator`;
  const valueSelectorName: `${string}.${"values" | "values.0"}` =
    listOperators.includes(ruleValue.operator)
      ? `${name}.values`
      : `${name}.values.0`;

  // The field changed. Reset the operator to default if the field type does not support the current operator
  useFormFieldUpdate(form, fieldSelectorName, (newValue) => {
    const currentOperator = form.getValues(operatorSelectorName);
    const field = allAttributes.find((field) => field.key === newValue);
    const fieldType = field?.type;

    const operatorMap = relevantOperators([
      "any",
      "date",
      "text",
      "number",
      "list",
      "boolean",
    ]);

    if (fieldType && !operatorMap[fieldType].includes(currentOperator)) {
      form.setValue(operatorSelectorName, operatorMap[fieldType][0]);
    }
  });

  // The operator changed. Reset value to default if the value type also changed
  useFormFieldDiff(form, operatorSelectorName, ({ oldValue, newValue }) => {
    const valueType = TypeByOperator[newValue];
    const previousValueType = oldValue && TypeByOperator[oldValue];

    if (valueType !== previousValueType) {
      form.setValue(valueSelectorName, defaultTypeValues[valueType], {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      // Validate value field, since the new operator might not work
      // form the existing value
      form.trigger(valueSelectorName);
    }
  });

  // Some times during development hotloading, ruleValue can be undefined because the form state resets
  if (!ruleValue) {
    return null;
  }

  const fieldValue = ruleValue.field;

  const fieldType = allAttributes.find((field) => field.key === fieldValue);

  const operators = relevantOperators([
    "any",
    "text",
    "date",
    "number",
    "list",
    "boolean",
  ]);

  return (
    <Grid
      alignItems="flex-start"
      data-testid="rule"
      gap={2}
      gridTemplateColumns="3fr 2fr 3fr auto"
      w="100%"
    >
      <AttributeFilterName
        allAttributes={allAttributes}
        isDisabled={isDisabled}
        name={fieldSelectorName}
      />

      <AttributeFilterOperatorPicker
        isDisabled={isDisabled}
        name={operatorSelectorName}
        operators={operators}
        rule={ruleValue}
      />

      <AttributeFilterValue
        fieldType={fieldType}
        isDisabled={isDisabled}
        name={valueSelectorName}
        rule={ruleValue}
      />

      {
        <IconButton
          aria-label="Remove rule"
          data-testid="rule-remove"
          icon={<RiCloseLine />}
          isDisabled={!canRemove || isDisabled}
          size={size}
          variant="ghost"
          isRound
          onClick={removeRule}
        />
      }
    </Grid>
  );
}

function AttributeFilterOperatorPicker({
  name,
  operators,
  rule,
  isDisabled = false,
}: {
  name: string;
  operators: ReturnType<typeof relevantOperators>;
  rule: AttributeFilterRuleType;
  isDisabled?: boolean;
}) {
  const { size } = useContext<AttributeFilterContextType>(
    AttributeFilterContext,
  );

  const form = useFormContext<{ [x: string]: AttributeFilterOperator }>();
  const value = form.watch(name);

  return (
    <RuleOperator
      isDisabled={rule.readOnly || isDisabled}
      operators={operators}
      size={size}
      value={value}
      onChange={(nextValue) => {
        form.setValue(name, nextValue, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      }}
    />
  );
}

function AttributeFilterName({
  name,
  allAttributes,
  isDisabled = false,
}: {
  name: string;
  allAttributes: AttributeField[];
  isDisabled?: boolean;
}) {
  const { size } = useContext<AttributeFilterContextType>(
    AttributeFilterContext,
  );

  const form = useFormContext();
  const { error } = form.getFieldState(name);

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormControl isDisabled={isDisabled} isInvalid={Boolean(error)}>
          <RuleAttribute
            attributes={allAttributes}
            size={size}
            value={field.value}
            onChange={field.onChange}
          />
        </FormControl>
      )}
    />
  );
}

function AttributeFilterValue({
  name,
  rule,
  fieldType,
  isDisabled = false,
}: {
  name: string;
  rule: AttributeFilterRuleType;
  fieldType?: AttributeField;
  isDisabled?: boolean;
}) {
  const type = getFieldTypeFromAttributeType(
    fieldType?.type ?? TypeByOperator[rule.operator],
  );

  const { size, entityType, eventName } =
    useContext<AttributeFilterContextType>(AttributeFilterContext);

  return (
    <ManagedFormControl
      data-testid="rule-value"
      isDisabled={isDisabled}
      name={name}
      render={({ field }) => {
        return type === "list" ? (
          <RuleValue
            attributeKey={rule.field}
            entityType={entityType}
            entityValue={eventName}
            fieldType={type}
            isDisabled={isDisabled}
            size={size}
            {...field}
          />
        ) : (
          <RuleValue
            attributeKey={rule.field}
            entityType={entityType}
            entityValue={eventName}
            fieldType={type}
            isDisabled={isDisabled}
            size={size}
            {...field}
          />
        );
      }}
    ></ManagedFormControl>
  );
}

export interface AttributeFilterContextType {
  entityType: "event" | "company";
  eventName?: string;
  size: InputProps["size"];
}

export const AttributeFilterContext = createContext<AttributeFilterContextType>(
  {
    entityType: "company",
    size: "md",
  },
);

export function AttributeFilters({
  attributes,
  name,
  minFilterCount = 0,
  canAdd = true,
  entityType = "company",
  eventName,
  size = "md",
  buttonText = "Add",
  isDisabled = false,
}: {
  name: string;
  attributes: AttributeField[];
  minFilterCount?: number;
  isLoadingAttributes?: boolean;
  canAdd?: boolean;
  entityType: "company" | "event";
  eventName?: string;
  size?: InputProps["size"];
  buttonText?: string;
  isDisabled?: boolean;
}) {
  const form = useFormContext<{ [x: string]: AttributeFilterRuleType[] }>();
  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name,
  });

  return (
    <AttributeFilterContext.Provider value={{ entityType, eventName, size }}>
      <VStack align="flex-start" data-testid="rules-list" maxW="wideForm">
        <AndOrList conjunction="and" direction="vertical">
          {rules.map((rule, index) => {
            const ruleFieldName = `${name}.${index}`;
            return (
              <VStack key={rule.id} alignItems="start">
                <RuleComponent
                  allAttributes={attributes}
                  canRemove={
                    rules.length > minFilterCount && rule.readOnly !== true
                  }
                  isDisabled={isDisabled}
                  name={ruleFieldName}
                  removeRule={() => remove(index)}
                />
              </VStack>
            );
          })}
        </AndOrList>

        {canAdd && (
          <Box>
            <Button
              background="appBackground"
              data-testid="rule-add"
              isDisabled={form.formState.isSubmitting || isDisabled}
              size="sm"
              variant="input"
              onClick={() => {
                append(defaultRuleTemplate(""), {
                  focusName: `${name}.${rules.length}.field`,
                });
                // Trigger validation on the new field right away
                form.trigger(`${name}.${rules.length}`);
              }}
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </VStack>
    </AttributeFilterContext.Provider>
  );
}
