import { useEffect } from "react";
import { RiCodeSSlashLine } from "react-icons/ri";
import {
  Link,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Spinner,
  Tooltip,
  useColorModeValue,
  useToast,
  VisuallyHidden,
} from "@chakra-ui/react";
import { useLocalStorage } from "usehooks-ts";

import { FeatureSettingsUrl, FeatureTargetingUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import HeaderLayout from "@/common/components/HeaderLayout";
import RouterTabs, { Route } from "@/common/components/RouterTabs";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FeatureTreeNavigation } from "@/feature/components/FeatureTreeNavigation";
import FeatureViewsPicker from "@/feature/components/FeatureViewsPicker";
import { RolloutStatusTag } from "@/feature/components/RolloutStatusTag";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureFeatureViews } from "@/feature/data/useFeatureFeatureViews";
import useFeatureFeatureViewsUpdateMutation from "@/feature/data/useFeatureFeatureViewsUpdateMutation";

export default function Feature() {
  const { featureId } = useParams();
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [showSetup, setShowSetup] = useLocalStorage(`${featureId}:setup`, true);
  const [subsegment] = useSubsegmentParam();
  const params = new URLSearchParams();
  if (subsegment.length) {
    subsegment.forEach((subsegment) => params.append("subsegment", subsegment));
  }
  const search = params.toString();

  const setupColor = useColorModeValue("teal.500", "teal.400");

  const { data: feature, isLoading, isError } = useFeatureData(featureId);
  const { data: featureViews = [], isLoading: isFeatureViewsLoading } =
    useFeatureFeatureViews(feature?.id);

  const { mutate: updateFeatureViews } = useFeatureFeatureViewsUpdateMutation(
    feature?.id as string,
  );

  const handleFeatureViewChange = (featureViewIds: string[]) => {
    updateFeatureViews(featureViewIds, {
      onSuccess: () => {
        segmentAnalytics.track("Feature FeatureViews updated", {
          numViews: featureViewIds.length,
        });
      },
    });
  };

  useEffect(() => {
    if (isError) {
      navigate("..");
      toast({
        title: "Could not retrieve feature",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, navigate, toast]);

  const tabsRoutes: Route[] = [
    {
      route: { ...useResolvedPath("analyze"), search },
      name: "Analyze",
    },
    {
      route: { ...useResolvedPath("targeting"), search },
      name: "Targeting",
    },
    {
      route: { ...useResolvedPath("settings"), search },
      name: "Settings",
    },
  ];

  const autoFeedbackSurveyColor = useColorModeValue("brand.500", "brand.400");

  return (
    <>
      <VisuallyHidden>
        <h1>{feature?.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <ButtonGroup spacing={4}>
            <FeatureViewsPicker
              isDisabled={isFeatureViewsLoading}
              isLoading={isFeatureViewsLoading}
              maxW={60}
              value={featureViews}
              onChange={handleFeatureViewChange}
            />
          </ButtonGroup>
        }
        title={
          isLoading || !feature ? (
            <Spinner size="sm" />
          ) : (
            <HStack ml={-3} spacing={4}>
              <FeatureTreeNavigation rootFeatureId={feature.id} />
              {feature.autoFeedbackSurveysEnabled && (
                <Tooltip
                  label="Automated feedback surveys are enabled"
                  maxW="52"
                  textAlign="center"
                  hasArrow
                >
                  <Box
                    as={Link}
                    color={autoFeedbackSurveyColor}
                    to={FeatureSettingsUrl(currentEnv!, feature, "feedback")}
                  >
                    <AutoFeedbackSurveysIcon
                      aria-hidden="true"
                      height={18}
                      width={18}
                    />
                  </Box>
                </Tooltip>
              )}
              <RolloutStatusTag
                featureId={featureId}
                link={FeatureTargetingUrl(currentEnv!, feature)}
              />
            </HStack>
          )
        }
      >
        {!isLoading && feature && (
          <RouterTabs
            rightActions={
              <ButtonGroup>
                {!showSetup && (
                  <Button
                    color={setupColor}
                    leftIcon={<RiCodeSSlashLine size={16} />}
                    variant="ghost"
                    onClick={() => setShowSetup(true)}
                  >
                    Show instructions
                  </Button>
                )}
              </ButtonGroup>
            }
            routes={tabsRoutes}
            tabListProps={{ px: 2 }}
            titleTemplate={`Feature › ${feature?.name} › %s`}
          />
        )}
      </HeaderLayout>
    </>
  );
}
