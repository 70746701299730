import { FormEvent, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { FaGoogle } from "react-icons/fa";
import { RiArrowRightLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  LoginErrorMessages,
  loginErrorMessagesSchema,
} from "@bucketco/shared/schemas/loginErrorSchema";
import { redirectUrlSchema } from "@bucketco/shared/schemas/redirectUrlSchema";
import { LoginUrl } from "@bucketco/shared/urls";

import CenteredLayout from "@/common/components/CenteredLayout";
import { API_URL } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

const HUBSPOT_FORM =
  "https://share-eu1.hsforms.com/1q4D1oYU7Rtq-kY6xGvO7Mw2b6w1x";

const getHubspotLink = (email: string) =>
  !email ? HUBSPOT_FORM : `${HUBSPOT_FORM}?email=${email}`;

const messageTypeToMessageMap: {
  [key in LoginErrorMessages]: React.ReactNode;
} = {
  no_email_error: "Invalid email address",
  only_company_emails_error: (
    <>
      Only work emails are allowed.
      <br />
      Please try again with your work email.
    </>
  ),
};

export default function Signup() {
  const locationSearchParams = new URLSearchParams(window.location.search);
  const parsedRedirect = redirectUrlSchema.safeParse(
    locationSearchParams.get("redirect"),
  );
  const errorMessageTypeParseResult = loginErrorMessagesSchema.safeParse(
    locationSearchParams.get("error"),
  );
  const errorMessage = errorMessageTypeParseResult.success
    ? messageTypeToMessageMap[errorMessageTypeParseResult.data]
    : null;

  const redirect = parsedRedirect.success ? parsedRedirect.data : "/";

  const queryParams = new URLSearchParams({
    redirect,
  });

  const isInvited = redirect.includes("/invite/");

  const loginUrl = `${API_URL}/auth/google?${queryParams.toString()}`;

  let header, text;

  if (isInvited) {
    header = "You're invited";
    text = "To accept your invitation, sign in below.";
  } else {
    header = "Create your account";
  }

  return (
    <CenteredLayout bg="appSidebarBackground" showBucketLogo>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box>
        <Flex alignItems="center" direction="column" gap={8} maxW="sm">
          <VStack spacing={3}>
            <Heading fontSize="3xl">{header}</Heading>
            {text && (
              <Text
                color="dimmed"
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
              >
                {text}
              </Text>
            )}
            <HStack fontSize="sm" spacing={1}>
              <Text color="dimmed" fontWeight="medium">
                Already have an account?
              </Text>
              <Link
                as={RouterLink}
                fontWeight="medium"
                to={LoginUrl(queryParams)}
              >
                Sign in
              </Link>
            </HStack>
          </VStack>
          <Card gap={4} p={8} px={12} variant="elevated">
            {errorMessage ? (
              <Alert status="warning">
                <AlertIcon />
                {errorMessage}
              </Alert>
            ) : null}
            <VStack spacing={3}>
              <Button
                as="a"
                href={loginUrl}
                leftIcon={<FaGoogle />}
                size="lg"
                variant="solid"
                onClick={() => {
                  segmentAnalytics.track(
                    "Google Authentication Button Clicked",
                  );
                }}
              >
                Sign up with Google
              </Button>
            </VStack>
            <Divider mx="auto" w={16} />
            <NonGoogleForm />
          </Card>
          <Text
            color="dimmed"
            fontSize="xs"
            fontWeight="medium"
            textAlign="center"
          >
            By continuing, you&apos;re agreeing to our{" "}
            <Link href="https://bucket.co/pages/policies" target="_blank">
              policies
            </Link>
            .
          </Text>
        </Flex>
      </Box>
    </CenteredLayout>
  );
}

function NonGoogleForm() {
  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    segmentAnalytics.track("Non-google Button Clicked");
    const email = (document.getElementById("email-field") as HTMLInputElement)
      ?.value;
    window.location.assign(getHubspotLink(email));
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={2}>
        <Text color="dimmed" fontSize="xs">
          Not using Google? Let us know
        </Text>
        <HStack spacing={1}>
          <Input
            id="email-field"
            placeholder="E-mail"
            size="sm"
            type="email"
            w="40"
          />
          <IconButton
            aria-label="send"
            icon={<RiArrowRightLine size={16} />}
            size="sm"
            type="submit"
            variant="outline"
          />
        </HStack>
      </VStack>
    </form>
  );
}
