import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import featureViewsQueryKeys from "./featureViewsQueryKeys";

export function useFeatureViewData(viewId?: string | null) {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: featureViewsQueryKeys.single(appId, viewId),
    queryFn: async () => {
      if (viewId === null) {
        const res = await api.get<"/apps/:appId/feature-views">(
          `/apps/${appId}/feature-views`,
        );

        return res.data.find((d) => d.isAllFeatureView);
      }

      const res = await api.get<"/apps/:appId/feature-views/:viewId">(
        `/apps/${appId}/feature-views/${viewId}`,
      );
      return res.data;
    },

    enabled: !!appId && viewId !== undefined,
  });
}
