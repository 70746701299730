import { useCallback, useMemo } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ButtonGroup, Flex } from "@chakra-ui/react";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureMetricsHistoricalQuery } from "@bucketco/shared/featureAPI";
import {
  WidgetDTO,
  WidgetFeatureMetricConfiguration,
  WidgetFeatureMetrics,
} from "@bucketco/shared/widgetAPI";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormCancel from "@/common/components/Form/FormCancel";
import { FormFrequencyPicker } from "@/common/components/Form/FormFrequencyPicker";
import FormNumberInput from "@/common/components/Form/FormNumberInput";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { Period, periodList } from "@/common/components/PeriodPicker";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { fetchFeatureHistoricalData } from "@/feature/data/useFeatureHistoricalData";
import { WidgetMetricSelector } from "@/widget/components/WidgetFeatureMetric/WidgetMetricSelector";
import useWidgetMetricOptions from "@/widget/hooks/useWidgetMetricOptions";
import {
  getCurrentValue,
  getSuggestedThreshold,
  periodToDates,
} from "@/widget/utils/widget";
import { FormRootError } from "../../../common/components/Form/FormRootError";

export function WidgetForm({
  form,
  configuration,
  implicitConfiguration = {},
  submitLabel = "Save",
  widgetMutation,
  onDone,
}: {
  form: UseFormReturn<WidgetFeatureMetricConfiguration>;
  configuration: WidgetFeatureMetricConfiguration;
  implicitConfiguration?: Partial<WidgetFeatureMetricConfiguration>;
  submitLabel?: string;
  widgetMutation: UseMutationResult<WidgetDTO, AxiosError<ErrorResponse>, any>;
  onDone: (configuration?: WidgetFeatureMetricConfiguration) => void;
}) {
  const { appId, envId } = useCurrentEnv();
  const [period] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });

  const { startDate, endDate } = useMemo(() => periodToDates(period), [period]);

  const metrics = useWidgetMetricOptions();
  const queryClient = useQueryClient();

  const submitHandler = getFormMutationSubmitHandler(
    form,
    widgetMutation,
    (widget) =>
      onDone(widget.configuration as WidgetFeatureMetricConfiguration),
    {
      prepareVariables: (values) => ({ configuration: values }),
    },
  );

  const isPercentage =
    WidgetFeatureMetrics[configuration.metric].type === "percentage";

  const { data: selectedFeature } = useFeatureData(configuration.featureId);

  const selectedMetric = metrics.find((m) =>
    m.measurements.some((mm) => mm.id === configuration.metric),
  );
  const selectedMeasurement = selectedMetric?.measurements.find(
    (mm) => mm.id === configuration.metric,
  );

  const recalculateThreshold = useCallback(
    async (configuration: WidgetFeatureMetricConfiguration) => {
      const params = {
        envId: envId!,
        metric: configuration.metric,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      } satisfies FeatureMetricsHistoricalQuery;
      let newCurrentValue = 0;
      try {
        // Use queryClient to deduplicate request with useWidgetData
        const data = await queryClient.fetchQuery({
          queryKey: featureQueryKeys.singleMetricsHistorical(
            appId,
            envId,
            configuration.featureId,
            params,
          ),
          queryFn: fetchFeatureHistoricalData(
            appId,
            configuration.featureId,
            params,
          ),
        });
        newCurrentValue = getCurrentValue(data.timeseries) ?? 0;
      } catch (error) {
        // If fetch fails we fall back to 0.
      }
      const suggestedThreshold = getSuggestedThreshold(
        form.getValues("metric"),
        newCurrentValue,
      );
      if (form.getValues("threshold") !== suggestedThreshold) {
        form.setValue("threshold", suggestedThreshold);
      }
    },
    [appId, endDate, envId, form, queryClient, startDate],
  );

  return (
    <FormProvider {...form}>
      <Flex as="form" direction="column" gap={4} onSubmit={submitHandler}>
        {!implicitConfiguration.featureId && (
          <ManagedFormControl
            label="Feature"
            name="featureId"
            placeholder="Select a feature"
            render={({ field }) => (
              <FeatureAutocompleteSelect
                {...field}
                usePortal
                onChange={(featureName) => {
                  field.onChange(featureName?.id ?? null);
                  if (featureName) {
                    recalculateThreshold({
                      ...configuration,
                      featureId: featureName.id,
                    });
                  }
                }}
              />
            )}
          />
        )}

        {!implicitConfiguration.metric && (
          <ManagedFormControl
            label="Metric"
            name="metric"
            render={({ field }) => (
              <WidgetMetricSelector
                {...field}
                selectedFeature={selectedFeature}
                onChange={(metric) => {
                  field.onChange(metric);
                  recalculateThreshold({
                    ...configuration,
                    metric,
                  });
                }}
              />
            )}
          />
        )}

        {!implicitConfiguration.threshold && (
          <Flex direction="column" gap={2}>
            {selectedMeasurement?.id === "averageFrequency" ? (
              <FormFrequencyPicker
                flexGrow={1}
                label="Target"
                name="threshold"
                size="sm"
                usePortal
              />
            ) : isPercentage ? (
              <FormNumberInput
                flexGrow={1}
                label="Target"
                max={
                  selectedMeasurement
                    ? selectedMeasurement.maxThreshold * 100
                    : undefined
                }
                min={1}
                name="threshold"
                scaleFactor={100}
                size="sm"
                unit={"%"}
              />
            ) : (
              <FormNumberInput
                flexGrow={1}
                label="Target"
                max={
                  selectedMeasurement
                    ? selectedMeasurement.maxThreshold
                    : undefined
                }
                min={1}
                name="threshold"
                scaleFactor={1}
                size="sm"
              />
            )}
          </Flex>
        )}

        <FormRootError />

        <ButtonGroup>
          <FormSubmitLegacy allowPristine>{submitLabel}</FormSubmitLegacy>
          <FormCancel color="dimmed" onClick={() => onDone(configuration)}>
            Cancel
          </FormCancel>
        </ButtonGroup>
      </Flex>
    </FormProvider>
  );
}
