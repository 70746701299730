import { isValidElement, ReactElement } from "react";
import { Navigate, To } from "react-router";
import { Center, Spinner } from "@chakra-ui/react";

import { AvailableFeatures, useFeature } from "@/common/hooks/useFeatureFlags";

export function FeatureFlaggedRoute({
  featureKey,
  primary,
  fallback,
}: {
  featureKey: keyof AvailableFeatures;
  primary: ReactElement | To;
  fallback: ReactElement | To;
}) {
  const { isLoading, isEnabled } = useFeature(featureKey);

  if (isLoading) {
    return (
      <Center>
        <Spinner color="dimmed" size="sm" />
      </Center>
    );
  }

  if (isEnabled) {
    return isValidElement(primary) ? (
      primary
    ) : (
      <Navigate to={primary as To} replace />
    );
  } else {
    return isValidElement(fallback) ? (
      fallback
    ) : (
      <Navigate to={fallback as To} replace />
    );
  }
}
