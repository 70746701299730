import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import AdminIndex from "@/admin";
import RequireAdmin from "@/admin/components/RequireAdmin";
import AdminOrg from "@/admin/pages/AdminOrg";
import AppLayout from "@/app/components/AppLayout";
import { FeatureFlaggedRoute } from "@/app/components/FeatureFlaggedRoute";
import GetStarted from "@/app/pages/GetStarted";
import Invite from "@/app/pages/Invite";
import NewApp from "@/app/pages/NewApp";
import Tracking from "@/app/pages/Tracking";
import TrackingDistinct from "@/app/pages/TrackingDistinct";
import TrackingRecent from "@/app/pages/TrackingRecent";
import GoToHome from "@/auth/components/GoToHome";
import OrgSuspended from "@/auth/components/OrgSuspended";
import RequireAuth from "@/auth/components/RequireAuth";
import RequireEnvironment from "@/auth/components/RequireEnvironment";
import RequireUnsuspendedOrg from "@/auth/components/RequireUnsuspendedOrg";
import { AuthProvider, useAuthContext } from "@/auth/contexts/authContext";
import Login from "@/auth/pages/Login";
import Signup from "@/auth/pages/Signup";
import { FeatureFlagsProvider } from "@/common/hooks/useFeatureFlags";
import Companies from "@/company/pages/Companies";
import Company from "@/company/pages/Company";
import { CompanyAttributes } from "@/company/pages/CompanyAttributes";
import CompanyFeatures from "@/company/pages/CompanyFeatures";
import CompanyFeedback from "@/company/pages/CompanyFeedback";
import CompanyUsers from "@/company/pages/CompanyUsers";
import { OrphanFeatureDropTarget } from "@/feature/components/OrphanFeatureDropTarget";
import Feature from "@/feature/pages/Feature";
import FeatureAnalyze from "@/feature/pages/FeatureAnalyze";
import Features from "@/feature/pages/Features";
import FeatureSettings from "@/feature/pages/FeatureSettings";
import FeaturesLegacy from "@/feature/pages/FeaturesLegacy";
import FeatureTargeting from "@/feature/pages/FeatureTargeting";
import FeatureTargetingVersions from "@/feature/pages/FeatureTargetingVersions";
import FeatureViewReportScreenshot from "@/feature/pages/FeatureViewReportScreenshot";
import NewFeature from "@/feature/pages/NewFeature";
import Feedbacks from "@/feedback/pages/Feedbacks";
import { EditFeedback, NewFeedback } from "@/feedback/pages/NewFeedback";
import GlobalSettings from "@/global-settings/pages/GlobalSettings";
import InvalidRoute from "../pages/InvlidRoute";

import "@/app/styles/app.css";

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        element={<FeatureViewReportScreenshot />}
        path="apps/:appId/screenshots/feature-view-report"
      />
      <Route element={<Login />} path="login" />
      <Route element={<Signup />} path="signup" />
      <Route
        element={
          <AuthProvider>
            <FeatureFlagsProvider>
              <RequireAuth>
                <RequireUnsuspendedOrg>
                  <Outlet />
                </RequireUnsuspendedOrg>
              </RequireAuth>
            </FeatureFlagsProvider>
          </AuthProvider>
        }
      >
        <Route element={<GoToHome />} path="/" />
        <Route element={<OrgSuspended />} path="org-suspended" />
        <Route element={<RequireAdmin />} path="admin">
          <Route element={<AdminIndex />} index />
          <Route element={<AdminOrg />} path=":orgId" />
        </Route>

        <Route
          element={<Invite />}
          path="/orgs/:orgName-:orgId/invite/:inviteKey"
        />
        <Route element={<GetStarted />} path="get-started" />
        <Route element={<NewApp />} path="apps/new" />
        <Route element={<LegacyAppRedirect />} path="apps/:appName-:appId/*" />

        <Route
          element={
            <RequireEnvironment>
              <AppLayout>
                <Outlet />
                <OrphanFeatureDropTarget />
              </AppLayout>
            </RequireEnvironment>
          }
          path="envs/:envName-:envId"
        >
          <Route element={<Navigate to="features" replace />} path="" />

          <Route
            element={
              <FeatureFlaggedRoute
                fallback={<FeaturesLegacy />}
                featureKey="data-table-rework"
                primary={<Features />}
              />
            }
            path="features"
          >
            <Route element={<NewFeature />} path="new" />
          </Route>

          <Route element={<Feature />} path="features/:featureName-:featureId">
            <Route element={<Navigate to="analyze" replace />} path="" />
            <Route element={<FeatureAnalyze />} path="analyze" />
            <Route element={<FeatureTargeting />} path="targeting">
              <Route element={<FeatureTargetingVersions />} path="versions" />
            </Route>
            <Route path="settings">
              <Route element={<Navigate to="general" replace />} path="" />
              <Route element={<FeatureSettings />} path=":section" />
            </Route>
          </Route>

          <Route path="settings">
            <Route element={<Navigate to="org-general" replace />} path="" />
            <Route element={<GlobalSettings />} path=":section" />
          </Route>

          <Route element={<Companies />} path="companies"></Route>
          <Route element={<Company />} path="companies/:companyId">
            <Route element={<Navigate to="features" replace />} path="" />
            <Route element={<CompanyFeatures />} path="features" />
            <Route element={<CompanyFeedback />} path="feedback">
              <Route element={<NewFeedback />} path="new" />
              <Route element={<EditFeedback />} path="edit/:feedbackId" />
            </Route>
            <Route element={<CompanyUsers />} path="users" />
            <Route element={<CompanyAttributes />} path="attributes" />
          </Route>

          <Route element={<Tracking />} path="tracking">
            <Route element={<Navigate to="recent" replace />} path="" />
            <Route element={<TrackingRecent />} path="recent" />
            <Route element={<TrackingDistinct />} path="distinct" />
          </Route>

          <Route element={<Feedbacks />} path="feedback">
            <Route element={<NewFeedback />} path="new" />
            <Route element={<EditFeedback />} path="edit/:feedbackId" />
          </Route>
        </Route>
      </Route>

      <Route element={<InvalidRoute />} path="*" />
    </Routes>
  );
}

// TODO: Remove this once we think the apps/:appName-:appId/* route is no longer in use
function LegacyAppRedirect() {
  const { currentOrg, currentEnv } = useAuthContext();
  const { appId, "*": splat } = useParams();

  // If there is a stored environment, redirect to that environment
  if (currentEnv?.appId === appId) {
    return <Navigate to={`${EnvironmentUrl(currentEnv!)}/${splat}`} replace />;
  }

  // Otherwise, redirect to the first environment
  const env = currentOrg?.apps.find(({ id }) => id === appId)?.environments[0];
  if (env) {
    return <Navigate to={`${EnvironmentUrl(env)}/${splat}`} replace />;
  }

  // If the app doesn't exist, redirect to the home page
  return <Navigate to="/" replace />;
}
