import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  chakra,
  Divider,
  Flex,
  FlexProps,
  Heading,
  HStack,
  ResponsiveValue,
  Spacer,
  Spinner,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { FeatureDetail } from "@bucketco/shared/featureAPI";
import {
  CreateFlagVersionArgsSchema,
  Flag,
  FlagRule as FlagRuleDTO,
} from "@bucketco/shared/flagAPI";
import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";
import { FeatureTargetingUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EnvironmentsSvg from "@/common/assets/environments-dots-circles-fill.svg?react";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { SlackChannelAutocompleteSelect } from "@/common/components/slack/SlackChannelAutocompleteSelect";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { FlagRulesDisplay } from "@/flags/components/FlagRulesDisplay";
import FlagRulesForm from "@/flags/components/FlagRulesForm";
import { useEnvironmentFlagVersionsData } from "@/flags/data/useEnvironmentFlagVersionsData";
import { useFlagData } from "@/flags/data/useFlagData";
import { useFlagVersionCreateMutation } from "@/flags/data/useFlagVersionCreateMutation";

const EnvironmentsIcon = chakra(EnvironmentsSvg);

type Props = {
  flagId?: string | null;
};

export default function FlagTargeting({ flagId: propFlagId }: Props) {
  const { flagId = propFlagId } = useParams();
  const { data: flag, isLoading, isError } = useFlagData(flagId);
  const errorToast = useErrorToast();

  useEffect(() => {
    segmentAnalytics.page("Feature Targeting");
  });

  useEffect(() => {
    if (isError) {
      errorToast({
        description: "Failed to load targeting rules. Please try again later",
      });
    }
  }, [isError, errorToast]);

  if (isLoading) {
    return (
      <Center flexGrow={1}>
        <Spinner size="sm" />;
      </Center>
    );
  }

  return flag && <Rules flag={flag} />;
}

type SlackNotificationStatusToggleProps = {
  slackChannel: SlackChannel;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
  value: boolean;
  onChange?: (value: boolean) => void;
};

function SlackNotificationStatusToggle({
  slackChannel,
  size = "md",
  value,
  onChange,
}: SlackNotificationStatusToggleProps) {
  return (
    <HStack>
      {slackChannel && (
        <HStack>
          <Switch
            isChecked={value}
            isReadOnly={!onChange}
            size={size == "xs" || size == "2xs" ? "sm" : size}
            onChange={(e) => {
              onChange && onChange(e.target.checked);
            }}
          />

          <Text color="gray.500" fontSize={size}>
            Send notifications to
          </Text>

          <SlackChannelAutocompleteSelect
            isActive={false}
            isDisabled={true}
            size={"xs"}
            value={slackChannel}
            onChange={() => {}}
          />
        </HStack>
      )}
    </HStack>
  );
}

function EnvironmentRules({
  flagId,
  feature,
  ...rest
}: FlexProps & { flagId: string; feature?: FeatureDetail }) {
  const toast = useToast();
  const navigate = useNavigate();

  const { currentEnv } = useAuthContext();
  const { data: flag } = useFlagData(flagId);
  const {
    data: environments = [],
    isLoading,
    isError,
  } = useEnvironmentFlagVersionsData(flagId);

  const otherEnvironments = useMemo(
    () => environments.filter((env) => env.id !== currentEnv?.id),
    [currentEnv?.id, environments],
  );

  const handleEnvEditChange =
    (environment: EnvironmentDTO, feature: FeatureDetail) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toast({
        title: `Environment changed to ${environment.name}`,
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      setTimeout(
        () => navigate(FeatureTargetingUrl(environment, feature)),
        100,
      );
    };

  return (
    <Flex flexDirection="column" gap={4} {...rest}>
      <Heading as="h3" color="dimmed" fontWeight="medium" size="sm">
        Rules in other environments:
      </Heading>
      {isLoading ? (
        <AnimatedSpinner size="sm" show />
      ) : isError ? (
        <Alert status="warning">
          <AlertIcon mt={1}>
            <EnvironmentsIcon />
          </AlertIcon>
          <AlertDescription>
            Failed to load targeting rules from the other environments
          </AlertDescription>
        </Alert>
      ) : !otherEnvironments.length ? (
        <Alert status="info">
          <AlertIcon mt={1}>
            <EnvironmentsIcon />
          </AlertIcon>
          <AlertDescription>
            No targeting rules have been set in other environments
          </AlertDescription>
        </Alert>
      ) : (
        otherEnvironments.map((environment) => (
          <Flex key={environment.id} direction="column" gap={2}>
            <Flex
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <EnvironmentDisplayName environment={environment} />
              <Button
                as={Link}
                bgColor="appBackground"
                size="xs"
                to={FeatureTargetingUrl(environment, feature!)}
                variant="outline"
                onClick={handleEnvEditChange(environment, feature!)}
              >
                Edit in {environment.name}
              </Button>
            </Flex>
            <FlagRulesDisplay
              envId={environment.id}
              flag={flag}
              rules={environment.version?.rules}
            />
          </Flex>
        ))
      )}
    </Flex>
  );
}

function Rules({ flag }: { flag: Flag }) {
  const { featureId } = useParams();
  const { currentEnv } = useAuthContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const [sendNotification, setSendNotification] = useState<boolean>();
  const toast = useToast();

  const { data: feature } = useFeatureData(featureId!);
  const flagVersionCreateMutation = useFlagVersionCreateMutation(
    featureId!,
    flag.id,
    sendNotification,
  );

  const form = useForm<z.input<typeof CreateFlagVersionArgsSchema>>({
    resolver: zodResolver(CreateFlagVersionArgsSchema),
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      rules:
        flag.currentVersions
          .find((cv) => cv.environment.id === currentEnv?.id)
          ?.rules.map(({ id: _, ...rest }) => ({
            ...rest,
          })) || [],
      changeDescription: "",
    },
  });

  const handleSubmit = getFormMutationSubmitHandler(
    form,
    flagVersionCreateMutation,
    (version) => {
      toast({
        title: "Targeting rules updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      form.reset({
        rules: version.rules.map(({ id: _, ...rest }) => rest),
        changeDescription: "",
      });
      setShowConfirm(false);
    },
  );

  return (
    <Flex direction="column" gap={4} maxW="4xl" padding={6}>
      <FormProvider {...form}>
        <form
          key="flag-rules"
          id="flag-rules"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirm(true);
          }}
        >
          <Flex direction="column" gap={4}>
            <FlagRulesForm flag={flag} />
            <FormRootError />
          </Flex>

          <ButtonGroup pt={4}>
            <FormSubmitLegacy form="flag-rules" />
            <FormReset />
          </ButtonGroup>
        </form>
        <ConfirmationDialog
          _contentProps={{ maxW: "2xl" }}
          description={
            <>
              <Text>Updating to these rules:</Text>

              <FlagRulesDisplay
                envId={currentEnv?.id}
                flag={flag}
                rules={form.getValues("rules") as unknown as FlagRuleDTO[]}
                w="full"
              />

              <ManagedFormControl
                name="changeDescription"
                render={({ field }) => (
                  <Textarea
                    placeholder="Add a description of the changes..."
                    {...field}
                  />
                )}
              />
            </>
          }
          isLoading={flagVersionCreateMutation.isPending}
          isOpen={showConfirm}
          title={
            <HStack>
              <Text>Confirm rules update</Text>
              <Spacer />
              {flag.slackChannel && (
                <SlackNotificationStatusToggle
                  size="xs"
                  slackChannel={flag.slackChannel}
                  value={sendNotification ?? flag.slackNotificationsEnabled}
                  onChange={setSendNotification}
                />
              )}
            </HStack>
          }
          onCancel={() => {
            form.resetField("changeDescription");
            setShowConfirm(false);
          }}
          onClose={() => {}}
          onConfirm={handleSubmit}
        />
      </FormProvider>

      <Divider my={3} />
      <EnvironmentRules feature={feature} flagId={flag.id} />
    </Flex>
  );
}
