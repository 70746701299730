import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ButtonGroup, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  DEFAULT_FEATURE_ADOPTION_SETTINGS,
  FeatureDetail,
  PatchFeatureArgsSchema,
} from "@bucketco/shared/featureAPI";

import FormRadioGroup from "@/common/components/Form/FormRadioGroup";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { FormCompanyAttributeFilterPickerInput } from "@/feature/components/Form/FormCompanyAttributeFilterPicker";
import { FormEventBasedFilterInputs } from "@/feature/components/Form/FormEventBasedFilterInputs";
import SettingsSTARSSteps from "@/feature/components/SettingsSTARSSteps";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";
import { useFlagData } from "@/flags/data/useFlagData";

export function SettingsUsage() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const defaultValues = {
    source: feature?.source,
    customEventSelectors: feature?.customEventSelectors,
    ...featureDefaultsForStarsConfig(feature!),
    usingItAttributeFilter: feature?.usingItAttributeFilter ?? [
      {
        field: "",
        operator: "IS",
        values: [""],
      },
    ],
  };

  const form = useForm<z.input<typeof PatchFeatureArgsSchema>>({
    resolver: zodResolver(PatchFeatureArgsSchema),
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
  });

  const updateMutation = useFeatureUpdateMutation(featureId!);
  const submitHandler = getFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset({
        source: result.source,
        customEventSelectors: result.customEventSelectors || [],
        usingItAttributeFilter: result.usingItAttributeFilter || [],
        adoptionEvaluationStrategy: result.adoptionEvaluationStrategy,
        adoptionWindowSizeInDays: result.adoptionWindowSizeInDays,
        adoptionStrategyEventCountMinEventCount:
          result.adoptionStrategyEventCountMinEventCount,
        adoptionStrategyFrequencyMinDaysCount:
          result.adoptionStrategyFrequencyMinDaysCount,
      });
    },
    {
      prepareVariables: (values) => ({
        id: featureId!,
        ...values,
      }),
    },
  );

  const chosenSource = form.watch("source");
  const { data: flag } = useFlagData(feature?.flagId);

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={6}
        maxW="wideForm"
        onSubmit={submitHandler}
      >
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          gap={3}
          maxW="mediumForm"
        >
          <FormRadioGroup
            itemDescriptionLocation="side"
            label="Usage criteria"
            name="source"
            options={[
              {
                label: "Event-based",
                value: "event",
                description: "User actions or custom events",
              },
              {
                label: "Attribute-based",
                value: "attribute",
                description: "Company attribute states",
              },
            ]}
          />

          {chosenSource === "event" ? (
            <FormEventBasedFilterInputs
              featureKey={flag?.key}
              name="customEventSelectors"
              w="full"
            />
          ) : (
            <FormCompanyAttributeFilterPickerInput
              buttonText="Add attribute filter"
              name="usingItAttributeFilter"
            />
          )}
        </Flex>
        {chosenSource === "event" && (
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            gap={3}
            w="mediumForm"
          >
            <FormRadioGroup
              itemDescriptionLocation="side"
              label="Adoption strategy"
              name="adoptionEvaluationStrategy"
              options={[
                {
                  label: "Frequency",
                  value: "frequency",
                  description: "Events on multiple days",
                },
                {
                  label: "Count",
                  value: "eventCount",
                  description: "Event count threshold",
                },
              ]}
            />
            <SettingsSTARSSteps />
          </Flex>
        )}

        <FormRootError />

        <ButtonGroup>
          <FormSubmitLegacy />
          <FormReset />
        </ButtonGroup>
      </Flex>
    </FormProvider>
  );
}

function featureDefaultsForStarsConfig(feature: FeatureDetail) {
  return {
    adoptionEvaluationStrategy: feature.adoptionEvaluationStrategy,
    adoptionWindowSizeInWeeks:
      (feature.adoptionWindowSizeInDays ||
        DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionWindowSizeInDays) / 7,
    adoptionStrategyEventCountMinEventCount:
      feature.adoptionStrategyEventCountMinEventCount ||
      DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionStrategyEventCountMinEventCount,
    adoptionStrategyFrequencyMinDaysCount:
      feature.adoptionStrategyFrequencyMinDaysCount ||
      DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionStrategyFrequencyMinDaysCount,
  };
}
