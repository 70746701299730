import React, { ReactNode } from "react";
import { QueryStatus } from "@tanstack/react-query";

import EmptyState, { EmptyStateProps } from "@/common/components/EmptyState";

type Props = Omit<EmptyStateProps, "isLoading"> & {
  status?: QueryStatus;
  children?: ReactNode;
  hasData?: boolean;
};

export const WidgetState = ({ status, hasData, children, ...rest }: Props) => {
  if (status === "success" && hasData) {
    return <>{children}</>;
  }
  return (
    <EmptyState
      description={
        status === "pending"
          ? null
          : status === "error"
          ? "Could not load feature metric"
          : "No feature metric data"
      }
      h="full"
      w="full"
      {...rest}
    />
  );
};
