import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { useAuthContext } from "@/auth/contexts/authContext";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useFeatureData } from "@/feature/data/useFeatureData";
import FlagVersions from "@/flags/components/FlagVersions";

export default function FeatureTargetingVersions() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const { currentEnv } = useAuthContext();

  return (
    <Modal
      size="6xl"
      isOpen
      onClose={() => {
        navigate({
          pathname: "..",
          search: searchParams.toString(),
        });
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>Targeting versions</Heading>
            <HStack fontSize="sm" spacing={1}>
              <Text color="dimmed" fontWeight="normal">
                {" "}
                for{" "}
              </Text>
              <EnvironmentDisplayName environment={currentEnv!} />
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FlagVersions
            featureId={featureId!}
            flagId={feature?.flagId ?? undefined}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
