import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiChat1Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnSort } from "@tanstack/react-table";
import omit from "lodash/omit";

import {
  FeatureListColumn,
  FeatureListItem,
  FeatureListQueryType,
  FeatureListSortBy,
  SortType,
} from "@bucketco/shared/featureAPI";
import { ColumnState } from "@bucketco/shared/types/columns";
import { FeatureAnalyzeUrl, FeatureViewUrl } from "@bucketco/shared/urls";
import { NonOptional } from "@bucketco/shared/utils/types";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";
import { useFeatureViewData } from "@/app/data/useFeatureViewData";
import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveys from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import CompactNumber from "@/common/components/CompactNumber";
import DataTableLegacy from "@/common/components/DataTableLegacy";
import FormInput from "@/common/components/Form/FormInput";
import FormRadioGroup from "@/common/components/Form/FormRadioGroup";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { FrequencyProgress } from "@/common/components/FrequencyProgress";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import PercentageNumber from "@/common/components/PercentageNumber";
import { SatisfactionRateProgress } from "@/common/components/SatisfactionRateProgress";
import SearchInput from "@/common/components/SearchInput";
import TableToolbar from "@/common/components/TableToolbar";
import TimestampCell from "@/common/components/TimestampCell";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTableLegacy from "@/common/hooks/useDataTableLegacy";
import useOutsideClickPopover from "@/common/hooks/useOutsideClickPopover";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { useTableDisplayMode } from "@/common/hooks/useTableDisplayMode";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import api from "@/common/utils/api";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { getItemTree } from "@/common/utils/listItemTree";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { FeatureNameCell } from "@/feature/components/FeatureNameCell";
import { FeaturesEmptyState } from "@/feature/components/FeaturesEmptyState";
import { FeatureSourceIcon } from "@/feature/components/FeatureSourceIcon";
import { GoalCell } from "@/feature/components/Goals/GoalCell";
import { RulesSummary } from "@/feature/components/RolloutStatus";
import { NoDataYet } from "@/feature/components/RolloutStatusTag";
import { StarsRateLabel } from "@/feature/components/StarsRateLabel";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";
import { useFeaturesSearch } from "@/feature/hooks/useFeaturesSearch";
import { useFeatureViewColumnStatesLegacy } from "@/feature/hooks/useFeatureViewColumnStatesLegacy";
import { useStarsFunnelColors } from "../hooks/useStarsFunnelColors";

const defaultColumns: Array<FeatureListColumn> = [
  "name",
  "rolloutEnvironment",
  "rolloutTargetingRules",
  "goals",
  "createdAt",
];

export function FeaturesTableLegacy({ viewId }: { viewId?: string | null }) {
  const { currentEnv } = useAuthContext();
  const { envId, appId } = useCurrentEnv();
  const navigate = useNavigate();
  const [subsegment] = useSubsegmentParam(["Active"]);
  const [displayMode] = useTableDisplayMode();

  const {
    columnStates,
    setColumnStates,
    columnSort,
    setColumnSort,
    isColumnStatesDirty,
    isColumnStatesFetching,
  } = useFeatureViewColumnStatesLegacy(viewId);

  const {
    isLoading,
    isFetching,
    data,
    setCanPaginate,
    setPaginateActions,
    fetchData,
  } = useDataTableLegacy<
    FeatureListItem,
    NonOptional<Omit<FeatureListQueryType, "envId">, "sortBy">,
    { sortType: SortType }
  >({
    apiCacheKey: featureQueryKeys.list(appId),
    apiHandler: (params) => () =>
      api
        .get<"/apps/:appId/features">(`/apps/${appId}/features`, {
          params: {
            ...omit(params, ["pageIndex", "pageSize"]),
            useTargetingRules: true,
            envId: envId!,
          },
        })
        .then((res) => res.data),
    apiOptions: {
      enabled: !!appId && !!envId,
      refetchInterval(data) {
        if (data) {
          if (
            "hasBootstrappingFeature" in data &&
            data.hasBootstrappingFeature
          ) {
            return 500;
          }
        }
        return false;
      },
      select({ data, ...rest }) {
        const hasBootstrappingFeature = data.some(
          (feature) => feature.processingStatus === "bootstrapping",
        );
        return {
          data:
            rest.metadata.sortType === "hierarchical"
              ? getItemTree(data, "sortKey")
              : data,
          hasBootstrappingFeature,
          ...rest,
        };
      },
    },
    defaultQueryParams: {
      sortBy: "createdAt",
      sortOrder: "desc",
      view: viewId ?? undefined,
      subsegment: subsegment[0],
      sortType: displayMode === "tree" ? "hierarchical" : "flat",
    },
  });

  useEffect(() => {
    fetchData({
      view: viewId ?? undefined,
      subsegment: subsegment[0],
      sortType: displayMode === "tree" ? "hierarchical" : "flat",
    });
  }, [fetchData, displayMode, viewId, subsegment]);

  const features = data?.data ?? [];

  const [searchQuery, setSearchQuery] = useSearchParam("q", { fallback: "" });
  const filteredFeatures = useFeaturesSearch(features, {
    enabled: true,
    predicate: (f) => {
      return (
        f.id === searchQuery ||
        f.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
  });

  const toolbar = (
    <FeaturesToolbar
      columnSort={columnSort}
      columnStates={columnStates}
      features={features}
      isColumnStatesDirty={isColumnStatesDirty}
      searchQuery={searchQuery}
      setColumnSort={setColumnSort}
      setColumnStates={setColumnStates}
      setSearchQuery={setSearchQuery}
      viewId={viewId}
    />
  );

  if (filteredFeatures.length === 0) {
    return (
      <>
        {toolbar}
        <FeaturesEmptyState isLoading={isLoading} viewId={viewId} />
      </>
    );
  }

  return (
    <>
      {toolbar}
      <DataTableLegacy
        columns={columns}
        columnStates={columnStates}
        data={filteredFeatures}
        defaultColumns={defaultColumns}
        expandedPersistenceId="features"
        fetchData={fetchData}
        isFetching={isFetching || isColumnStatesFetching}
        meta={data?.metadata}
        pageCount={1}
        rowOnClick={(row, e) => {
          const url = FeatureAnalyzeUrl(currentEnv!, row.original, {
            subsegment,
          });
          linkClickModifierHandler(url, (e) => {
            e.preventDefault();
            navigate(url);
          })(e);
        }}
        setCanPaginate={setCanPaginate}
        setColumnStates={setColumnStates}
        setPaginateActions={setPaginateActions}
        setSortBy={setColumnSort}
        sortBy={columnSort}
        trackingId="features"
        variant="clickable"
      />
    </>
  );
}

type FeatureViewForm =
  | { mode: "create"; newFeatureViewName: string }
  | { mode: "update"; newFeatureViewName: "" };

function FeaturesSave({
  viewId,
  features,
  columnStates,
  setColumnStates,
  columnSort,
  setColumnSort,
  isColumnStatesDirty,
}: {
  viewId?: string | null;
  features: FeatureListItem[];
  columnStates: ColumnState[];
  setColumnStates: (
    nextColumnStates: ColumnState[],
    isPersisted: boolean,
  ) => void;
  columnSort: ColumnSort;
  setColumnSort: (nextColumnSort: ColumnSort, isPersisted: boolean) => void;
  isColumnStatesDirty: boolean;
}) {
  const { currentApp, currentEnv } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: view, isFetching: isFetchingView } = useFeatureViewData(viewId);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { contentRef, triggerRef } = useOutsideClickPopover({
    isOpen,
    onToggle,
  });

  const form = useForm<FeatureViewForm>({
    defaultValues: {
      mode: "update",
      newFeatureViewName: "",
    },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FeatureViewForm) => {
      if (data.mode === "update") {
        await api.patch<"/apps/:appId/feature-views/:viewId">(
          `/apps/${currentApp?.id}/feature-views/${view!.id}`,
          {
            columns: columnStates as ColumnState<FeatureListColumn>[],
            columnSortKey: columnSort.id as FeatureListSortBy,
            columnSortDesc: columnSort.desc,
          },
        );

        setColumnStates(columnStates, true);
        setColumnSort(columnSort, true);
        form.reset();
        return;
      }

      // Create a new feature view
      const res = await api.post<"/apps/:appId/feature-views">(
        `/apps/${currentApp?.id}/feature-views`,
        {
          name: data.newFeatureViewName,
          featureIds: features.map((f) => f.id),
          columns: columnStates as ColumnState<FeatureListColumn>[],
          columnSortKey: columnSort.id as FeatureListSortBy,
          columnSortDesc: columnSort.desc,
        },
      );

      // Clean up and redirect to the new feature view
      await queryClient.invalidateQueries({
        queryKey: featureViewsQueryKeys.list(currentApp?.id),
      });
      onClose();
      navigate(FeatureViewUrl(currentEnv!, res.data.id));
      setColumnStates(columnStates, true);
      setColumnSort(columnSort, true);
      form.reset();
    },
  });

  const handleSubmit = form.handleSubmit((data) => mutateAsync(data));
  const mode = form.watch("mode");

  return (
    <HStack spacing={4}>
      {isColumnStatesDirty && (
        <Text
          color="dimmed"
          fontSize="sm"
          fontStyle="italic"
          whiteSpace="nowrap"
        >
          Column configuration unsaved
        </Text>
      )}
      <Popover
        autoFocus={false}
        closeOnEsc={true}
        isOpen={isOpen}
        placement="bottom-start"
        trigger="click"
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            ref={triggerRef}
            isDisabled={!isColumnStatesDirty}
            isLoading={isPending}
            size="sm"
            variant="outline"
            onClick={onToggle}
          >
            Save feature view
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={contentRef} w="auto">
          <PopoverBody minW="sm" p={5}>
            <PopoverArrow />
            <form onSubmit={handleSubmit}>
              <FormProvider {...form}>
                <FormRadioGroup
                  colorScheme="brand"
                  defaultValue="update"
                  name="mode"
                  options={[
                    { label: "Update feature view", value: "update" },
                    { label: "Create a new feature view", value: "create" },
                  ]}
                  size="sm"
                />
                {mode === "create" && (
                  <Box maxW={48} mt={2}>
                    <FormInput
                      name="newFeatureViewName"
                      placeholder="Feature view name"
                      size="sm"
                    />
                  </Box>
                )}
                <FormSubmitLegacy
                  isDisabled={isFetchingView || !isColumnStatesDirty}
                  isLoading={isPending}
                  mt={4}
                  size="sm"
                >
                  {mode === "update"
                    ? "Update feature view"
                    : "Create feature view"}
                </FormSubmitLegacy>
              </FormProvider>
            </form>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
}

function FeaturesToolbar({
  viewId,
  features,
  columnStates,
  setColumnStates,
  columnSort,
  setColumnSort,
  isColumnStatesDirty,
  searchQuery,
  setSearchQuery,
}: {
  viewId?: string | null;
  features: FeatureListItem[];
  columnStates: ColumnState[];
  setColumnStates: (
    nextColumnStates: ColumnState[],
    isPersisted: boolean,
  ) => void;
  columnSort: ColumnSort;
  setColumnSort: (nextColumnSort: ColumnSort, isPersisted: boolean) => void;
  isColumnStatesDirty: boolean;
  searchQuery: string;
  setSearchQuery: (nextSearchQuery: string) => void;
}) {
  return (
    <TableToolbar>
      <Box>
        <SearchInput
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
        />
      </Box>
      <FeaturesSave
        columnSort={columnSort}
        columnStates={columnStates}
        features={features}
        isColumnStatesDirty={isColumnStatesDirty}
        setColumnSort={setColumnSort}
        setColumnStates={setColumnStates}
        viewId={viewId}
      />
    </TableToolbar>
  );
}

const MultiValueFlexContainer = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => (
  <Flex
    alignItems="center"
    fontSize="sm"
    gap={2}
    whiteSpace="nowrap"
    {...props}
  >
    {children}
  </Flex>
);

const CellDisplayCount = ({
  percentage,
  count,
  color,
  ...rest
}: FlexProps & {
  percentage: number | null;
  count?: number | null;
  color?: string;
}) => {
  if (percentage === null) {
    return <NotAvailableCell />;
  }

  return (
    <MultiValueFlexContainer {...rest}>
      <Progress
        aria-hidden={true}
        color={color}
        max={1}
        min={0}
        minWidth={"60px"}
        size="sm"
        value={percentage}
      />
      <Box minW="3em" textAlign="right">
        <PercentageNumber value={percentage} />
      </Box>
      {typeof count == "number" && (
        <Box color="dimmed" minW="32px">
          <CompactNumber value={count} />
        </Box>
      )}
    </MultiValueFlexContainer>
  );
};

const CellDisplayRate = ({
  rate,
  color,
  ...rest
}: FlexProps & {
  rate: number | null;
  color?: string;
}) => {
  if (rate === null) {
    return <NotAvailableCell />;
  }

  return (
    <MultiValueFlexContainer {...rest}>
      <Progress
        aria-hidden={true}
        color={color}
        max={1}
        min={0}
        minWidth={"60px"}
        size="sm"
        value={rate}
      />
      <Box minW="72px">
        <StarsRateLabel rate={rate} />
      </Box>
    </MultiValueFlexContainer>
  );
};

const CellDisplayText = ({
  text,
  icon,
  tooltip,
  textColor,
  iconColor,
  ...rest
}: FlexProps & {
  text: string | JSX.Element | null;
  icon?: JSX.Element;
  tooltip?: string;
  textColor?: string;
  iconColor?: string;
}) => {
  if (!text) {
    return <NotAvailableCell />;
  }
  return (
    <MultiValueFlexContainer gap={1.5} {...rest}>
      <Box color={iconColor ?? "dimmed"}>{icon}</Box>
      <Tooltip label={tooltip} openDelay={300} hasArrow>
        <Text color={textColor}>{text}</Text>
      </Tooltip>
    </MultiValueFlexContainer>
  );
};

type FeatureListHeaderType = string; // Headers must be strings to support Slack digests

const columnHelper = createSortableColumnHelper<
  FeatureListItem,
  FeatureListSortBy,
  FeatureListHeaderType
>();

export const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: FeatureNameCell,
  }),
  columnHelper.accessor("rolloutEnvironment", {
    header: "Environment status",
    cell: function RolloutStatusRenderer(cell) {
      const value = cell.getValue();
      if (value == null) {
        return <NoDataYet fontSize="sm" />;
      }
      return <EnvironmentDisplayName environment={value} fontSize="sm" />;
    },
  }),
  columnHelper.accessor("rolloutTargetingRules", {
    header: "Targeting (Production)",
    enableSorting: false,
    cell: function RolloutTargetingRulesRenderer(cell) {
      const value = cell.getValue();
      return <RulesSummary fontSize="sm" rules={value} />;
    },
  }),
  columnHelper.accessor("goals", {
    header: "Goals",
    cell: function TotalGoalProgressRenderer(cell) {
      const value = cell.getValue();
      return <GoalCell goals={value} max={4} maxWithDescription={2} />;
    },
  }),
  columnHelper.accessor("triedCompaniesFraction", {
    header: "Tried",
    cell: function TriedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { triedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={triedColor}
          count={currentMetrics?.tried}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("adoptedCompaniesFraction", {
    header: "Adopted",
    cell: function AdoptedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { adoptedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={adoptedColor}
          count={currentMetrics?.adopted}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("retainedCompaniesFraction", {
    header: "Retained",
    cell: function RetainedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { retainedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={retainedColor}
          count={currentMetrics?.retained}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("satisfiedCompaniesFraction", {
    header: "Satisfied",
    cell: function SatisfiedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { satisfiedColor } = useStarsFunnelColors();
      return <CellDisplayCount color={satisfiedColor} percentage={value} />;
    },
  }),
  columnHelper.accessor("adoptionRate", {
    header: "Adoption rate",
    cell: function AdoptionRateRenderer(cell) {
      const value = cell.getValue();
      const { adoptedColor } = useStarsFunnelColors();
      return <CellDisplayRate color={adoptedColor} rate={value} />;
    },
  }),
  columnHelper.accessor("retentionRate", {
    header: "Retention rate",
    cell: function RetentionRateRenderer(cell) {
      const value = cell.getValue();
      const { retainedColor } = useStarsFunnelColors();
      return <CellDisplayRate color={retainedColor} rate={value} />;
    },
  }),
  columnHelper.accessor("satisfactionRate", {
    header: "Satisfaction rate",
    cell: (cell) => {
      const value = cell.getValue();
      if (value == null) {
        return <NotAvailableCell />;
      }
      return (
        <SatisfactionRateProgress fontSize="sm" satisfactionRate={value} />
      );
    },
  }),
  columnHelper.accessor("feedbackCountAll", {
    header: "Feedback",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          aria-label={`Based on ${value} submissions`}
          icon={<RiChat1Line size="16" />}
          text={value ? <CompactNumber value={value} /> : null}
        />
      );
    },
  }),
  columnHelper.accessor("averageFrequency", {
    header: "Frequency",
    cell: function AverageFrequencyRenderer(cell) {
      const value = cell.getValue();
      const { retainedColor } = useStarsFunnelColors();
      if (value === null) {
        return <NotAvailableCell />;
      }
      return (
        <MultiValueFlexContainer>
          <FrequencyProgress filledColor={retainedColor} frequency={value} />
        </MultiValueFlexContainer>
      );
    },
  }),
  columnHelper.accessor("source", {
    header: "Tracking source",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          icon={<FeatureSourceIcon source={value} />}
          minW="140px"
          text={value == "event" ? "Event" : "Company attributes"}
        />
      );
    },
  }),
  columnHelper.accessor("autoFeedbackSurveysEnabled", {
    header: "Automated feedback surveys",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          icon={
            <AutoFeedbackSurveys aria-hidden="true" height={18} width={18} />
          }
          text={value ? "Enabled" : null}
        />
      );
    },
  }),
  columnHelper.accessor("createdAt", {
    header: "Created",
    cell: (cell) => {
      const value = cell.getValue();
      return <TimestampCell value={value} />;
    },
  }),
];
