import slug from "slug";

import { CompanyFeature } from "@bucketco/shared/companyAPI";
import { FeatureListItem } from "@bucketco/shared/featureAPI";

type Feature = FeatureListItem | CompanyFeature;

type FeatureListItemWithSubRows<T extends Feature> = T & {
  subRows: T[];
};

export function featureHasSubRows<T extends Feature>(
  f: T,
): f is FeatureListItemWithSubRows<T> {
  return "subRows" in f && Array.isArray(f.subRows);
}

export function generateFeatureKey(
  input: string,
  existingKeys: string[],
): string {
  const keySlug = slug(input);

  if (!existingKeys.includes(keySlug)) {
    return keySlug;
  } else {
    const lastPart = keySlug.split("-").pop();

    if (!lastPart || isNaN(Number(lastPart))) {
      return `${keySlug}-1`;
    } else {
      const base = keySlug.slice(0, keySlug.length - lastPart.length);
      const newNumber = Number(lastPart) + 1;
      return `${base}${newNumber}`;
    }
  }
}
