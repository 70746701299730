import { RiBuildingLine, RiPieChartLine } from "react-icons/ri";

import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import EmptyState from "@/common/components/EmptyState";

export default function CompaniesEmptyState({
  segment,
}: {
  segment: SegmentDTO;
}) {
  if (segment.isAllSegment) {
    return (
      <EmptyState
        description="Companies represent groups of end-users. Companies will appear in Bucket when initializing our SDK or over the API."
        flexGrow={1}
        icon={<RiBuildingLine size="48px" />}
        title="No companies yet"
      />
    );
  }
  return (
    <EmptyState
      description="Try adjusting the filters. Tweak the table columns to show only what you need and save them for later!"
      flexGrow={1}
      icon={<RiPieChartLine size="48px" />}
      title="No companies in segment"
    />
  );
}
