import React, { ReactNode, useMemo, useRef } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import {
  Box,
  Flex,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  TableMeta,
  TableState,
  useReactTable,
} from "@tanstack/react-table";
import { useLocalStorage } from "usehooks-ts";

import { ColumnSort } from "@bucketco/shared/types/columns";

import {
  DataTableToolbar,
  DataTableToolbarFunctionalityProps,
} from "@/common/components/DataTable/DataTableToolbar";
import { DataTableFunctionalityProps } from "@/common/components/DataTable/DataTableTypes";
import ErrorBoundary from "@/common/components/ErrorBoundary";
import { FillScrollWrapper } from "@/common/components/FillScrollWrapper";
import { useOverflow } from "@/common/hooks/useOverflow";
import {
  getColumnIds,
  getId,
  getOrderFromStates,
  getVisibilityFromStates,
  stickyHorizontalProps,
  stickyVerticalProps,
} from "@/common/utils/datatable";
import { flattenItemTree } from "@/common/utils/listItemTree";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export type DataTableProps<
  TData extends { subRows?: TData[]; id: string },
  TMeta = unknown,
  TSortBy extends string = string,
  TColumn extends string = string,
> = DataTableFunctionalityProps<TSortBy, TColumn> &
  TableProps & {
    data: TData[];
    columns: ColumnDef<TData, any>[];
    defaultColumns?: string[];
    tableId?: string;
    meta?: TableMeta<TMeta>;
    isFetching: boolean;
    freezeHeader?: boolean;
    freezeFirstColumn?: boolean;
    scrollable?: boolean;
    toolbarLeftActions?: ReactNode;
    toolbarRightActions?: ReactNode;
    searchPlaceholder?: string;
    columnCustomizerFooter?: ReactNode;
    children?: ReactNode;
    onRowClick?: (
      row: Row<TData>,
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => void;
  };

function DataTableInner<
  TData extends { id: string; subRows?: TData[] },
  TMeta = unknown,
  TSortBy extends string = string,
  TColumn extends string = string,
>({
  columns,
  data,
  tableId,
  freezeHeader = true,
  freezeFirstColumn = true,
  isFetching,
  defaultColumns = getColumnIds(columns),
  scrollable = true,
  meta,
  toolbarLeftActions,
  toolbarRightActions,
  searchPlaceholder,
  columnCustomizerFooter,
  children,
  onRowClick,
  // Search
  canSearch,
  searchQuery,
  onSearchQueryChange,
  // Paginate
  canPaginate,
  pageIndex = 0,
  pageSize = 20,
  totalCount,
  onPageIndexChange,
  // Sort
  canSort,
  sorting,
  onSortingChange,
  // Column States
  canCustomize,
  columnStates,
  onColumnStatesChange,
  ...props
}: DataTableProps<TData, TMeta, TSortBy, TColumn>) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { refXScrollBegin } = useOverflow(scrollRef);

  const frozenColumnId = useMemo(() => {
    return freezeFirstColumn ? getId(columns[0]) : undefined;
  }, [freezeFirstColumn, columns]);

  const [collapsedItems, setCollapsedItems] = useLocalStorage<string[]>(
    `collapsed.${tableId}` ?? "N/A",
    [],
    {},
  );

  const tableState: Partial<TableState> = {
    sorting: canSort ? sorting : undefined,
    pagination: canPaginate
      ? {
          pageIndex,
          pageSize,
        }
      : undefined,
    columnVisibility: getVisibilityFromStates(
      columns,
      columnStates || [],
      defaultColumns,
      frozenColumnId,
    ),
    columnOrder: getOrderFromStates(
      columns,
      columnStates || [],
      frozenColumnId,
    ),
  };

  if (tableId && collapsedItems) {
    const expandedState: ExpandedState = {};
    const expandedItems = flattenItemTree(data).filter(
      (i) => !collapsedItems.includes(i.id),
    );

    for (const expandedItem of expandedItems) {
      expandedState[expandedItem.id] = true;
    }

    tableState.expanded = expandedState;
  }

  const table = useReactTable<TData>({
    columns,
    data,
    rowCount: totalCount,
    state: tableState,
    meta,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableSorting: canSort ?? false,
    enableSortingRemoval: false,
    enableMultiSort: false,
    enableHiding: true,
    enableExpanding: true,
    autoResetExpanded: false,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getSubRows: (row) => {
      return "subRows" in row ? (row.subRows as TData[]) : [];
    },
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel<TData>(),
    getExpandedRowModel: getExpandedRowModel<TData>(),
    onPaginationChange: (updater) => {
      if (typeof updater !== "function") return;
      const pagination = updater(table.getState().pagination);
      onPageIndexChange?.(pagination.pageIndex);
    },
    onSortingChange: (updater) => {
      if (typeof updater !== "function") return;
      const sorting = updater(table.getState().sorting);
      onSortingChange?.(sorting as ColumnSort<TSortBy>[]);
    },
    onExpandedChange: (updater) => {
      if (typeof updater !== "function") return;
      const before = table.getState().expanded;
      const after = updater(before);

      segmentAnalytics.track("Feature Tree Collapse Toggled", {
        table: tableId,
        expanded_count: Object.keys(after).length,
      });

      if (tableId) {
        const expandedItems = Object.keys(after);

        const collapsedItems = flattenItemTree(data)
          .map((i) => i.id)
          .filter((id) => !expandedItems.includes(id));

        setCollapsedItems(collapsedItems);
      } else {
        table.setState((prev) => ({ ...prev, expanded: after }));
      }
    },
    onStateChange: (updater) => {
      // Individual state changes are handled by the onChange handlers above
      if (typeof updater !== "function") return;
      const newState = updater(table.getState());
      const columnStates = newState.columnOrder.map((key) => ({
        id: key as TColumn,
        shown: newState.columnVisibility[key],
      }));
      onColumnStatesChange?.(columnStates);
    },
  });

  return (
    <ErrorBoundary>
      <DataTableToolbar
        columnCustomizerFooter={columnCustomizerFooter}
        frozenColumnId={frozenColumnId}
        leftActions={toolbarLeftActions}
        rightActions={toolbarRightActions}
        searchPlaceholder={searchPlaceholder}
        table={table}
        tableId={tableId}
        {...({
          // Search
          canSearch,
          searchQuery,
          onSearchQueryChange,
          // Paginate
          canPaginate,
          // Column States
          canCustomize,
        } as DataTableToolbarFunctionalityProps<TColumn>)}
      />
      <FillScrollWrapper ref={scrollRef} enabled={scrollable}>
        <Table isolation="isolate" {...props}>
          {children}
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <Th
                    key={header.id}
                    cursor={
                      canSort && header.column.getCanSort()
                        ? "pointer"
                        : undefined
                    }
                    py={2}
                    style={{
                      maxWidth: `${header.column.columnDef.maxSize}px`,
                      minWidth: `${header.column.columnDef.minSize}px`,
                      width:
                        header.getSize() === Number.MAX_SAFE_INTEGER
                          ? "auto"
                          : `${header.getSize()}px`,
                    }}
                    onClick={header.column.getToggleSortingHandler()}
                    {...(freezeHeader && stickyVerticalProps)}
                    {...(freezeFirstColumn &&
                      index === 0 && {
                        ...stickyHorizontalProps(
                          !refXScrollBegin ? "appBorder" : "transparent",
                          2,
                        ),
                      })}
                  >
                    <Flex alignItems="center" justifyContent="start">
                      <Box isTruncated>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Flex alignItems="center" position="relative">
                        {{
                          asc: (
                            <RiArrowDropUpLine
                              aria-label="sorted ascending"
                              size={24}
                            />
                          ),
                          desc: (
                            <RiArrowDropDownLine
                              aria-label="sorted descending"
                              size={24}
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <Box height="24px" width="24px"></Box>
                        )}
                        <Flex
                          alignItems="center"
                          height="24px"
                          justifyContent="center"
                          position="absolute"
                          right="-16px"
                          width="16px"
                        >
                          {header.column.getIsSorted() && isFetching && (
                            <Spinner size="xs" />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr
                  key={row.id}
                  id={row.id}
                  onClick={onRowClick ? (e) => onRowClick(row, e) : undefined}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Td
                      key={cell.id}
                      style={{
                        maxWidth: `${cell.column.columnDef.maxSize}px`,
                        minWidth: `${cell.column.columnDef.minSize}px`,
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                      }}
                      verticalAlign="top"
                      {...(freezeFirstColumn &&
                        index === 0 && {
                          ...stickyHorizontalProps(
                            !refXScrollBegin ? "appBorder" : "transparent",
                          ),
                        })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </FillScrollWrapper>
    </ErrorBoundary>
  );
}

// Memoize component so it doesn't re-render when props don't change
export const DataTable = React.memo(DataTableInner) as typeof DataTableInner;
