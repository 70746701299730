import { forwardRef, memo, ReactElement } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  BoxProps,
  chakra,
  ColorProps,
  Flex,
  FlexProps,
  Link as ChakraLink,
  LinkProps,
  Spinner,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import omit from "lodash/omit";

import { ProcessingStatus } from "@bucketco/shared/featureAPI";
import { FeatureAnalyzeUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import FeatureSvg from "@/common/assets/feature-icon.svg?react";
import { highlightStringMatch } from "@/common/components/AutocompleteSelect";
import { useFeatureParentNames } from "@/common/hooks/useFeatureParentNames";

const FeatureIcon = chakra(FeatureSvg);

const sizingPropMap = {
  sm: {
    container: {
      fontSize: "sm",
      fontWeight: "medium",
      gap: 1.5,
    },
    icon: {
      size: 14,
      flexShrink: 0,
    },
  },
  md: {
    container: {
      fontSize: "md",
      fontWeight: "medium",
      gap: 1.5,
    },
    icon: {
      size: 16,
      flexShrink: 0,
    },
  },
  lg: {
    container: {
      fontSize: "lg",
      fontWeight: "medium",
      gap: 2,
    },
    icon: {
      size: 18,
      flexShrink: 0,
    },
  },
  xl: {
    container: {
      fontSize: "xl",
      fontWeight: "medium",
      gap: 2,
    },
    icon: {
      size: 18,
      flexShrink: 0,
    },
  },
} as const;

type FeatureDisplaySize = keyof typeof sizingPropMap;
type FeatureDisplayProps = {
  feature: {
    id: string;
    name: string;
    processingStatus?: ProcessingStatus;
    parentFeatureId?: string | null;
    autoFeedbackSurveysEnabled?: boolean;
  };
  size?: FeatureDisplaySize;
  highlightText?: string;
  icon?: ReactElement;
  showIcon?: boolean;
  iconSize?: number;
  iconColor?: ColorProps["color"];
  includeParents?: boolean;
  subsegmentId?: string;
  autoFeedbackSurveyIndicator?: boolean;
} & (
  | ({ link: true } & Omit<LinkProps, "size">)
  | ({ link?: false | undefined } & BoxProps)
);

export const FeatureDisplay = memo(
  forwardRef<HTMLElement, FeatureDisplayProps>(
    (
      {
        feature,
        size = "md",
        highlightText,
        icon,
        showIcon = true,
        iconSize = sizingPropMap[size].icon.size,
        iconColor,
        includeParents,
        subsegmentId,
        autoFeedbackSurveyIndicator = false,
        ...props
      },
      ref,
    ) => {
      const { currentEnv } = useAuthContext();
      const commonFlexProps = {
        display: "flex",
        alignItems: "center",
        minW: 0,
        gap: 1,
      };

      const sizingProps = sizingPropMap[size];

      iconColor = iconColor ?? "dimmed";

      const autoFeedbackSurveyColor = useColorModeValue(
        "brand.500",
        "brand.400",
      );

      const content = (
        <>
          {showIcon &&
            (icon || (
              <FeatureIcon
                boxSize={`${iconSize}px`}
                color={iconColor}
                flexShrink={0}
              />
            ))}
          <FeatureNameDisplay
            feature={feature}
            flex="1 1 auto"
            highlightText={highlightText}
            includeParents={includeParents}
          />
          {autoFeedbackSurveyIndicator &&
            feature.autoFeedbackSurveysEnabled && (
              <Tooltip
                label="Automated feedback surveys are enabled"
                maxW="52"
                textAlign="center"
                hasArrow
              >
                <Box color={autoFeedbackSurveyColor} ml={2} mt={0.5}>
                  <AutoFeedbackSurveysIcon
                    aria-hidden="true"
                    height={18}
                    width={18}
                  />
                </Box>
              </Tooltip>
            )}
          {feature.processingStatus === "bootstrapping" && (
            <Box flex="1 1 auto">
              <Spinner color="dimmed" mr="auto" size="xs" />
            </Box>
          )}
        </>
      );

      if (props.link) {
        return (
          <ChakraLink
            ref={ref}
            _hover={{ textDecoration: "underline" }}
            as={Link}
            textDecoration="none"
            to={FeatureAnalyzeUrl(currentEnv!, feature, {
              subsegment: subsegmentId,
            })}
            {...commonFlexProps}
            {...sizingProps.container}
            {...omit(props, "link")}
          >
            {content}
          </ChakraLink>
        );
      }

      return (
        <Box
          ref={ref}
          {...commonFlexProps}
          {...sizingProps.container}
          {...omit(props, "link")}
        >
          {content}
        </Box>
      );
    },
  ),
);

FeatureDisplay.displayName = "FeatureDisplay";

export function FeatureNameDisplay({
  feature,
  includeParents = true,
  highlightText,
  ...flexProps
}: FlexProps &
  Pick<FeatureDisplayProps, "feature" | "includeParents" | "highlightText">) {
  const featureParentNames = useFeatureParentNames(feature.id);

  const featureName = highlightText
    ? highlightStringMatch(feature.name, highlightText)
    : feature.name;

  return (
    <Flex as="span" {...flexProps} display="flex" gap="1" minW="0">
      {includeParents
        ? featureParentNames.flatMap((p) => [
            <Box
              key={`parent-${feature.id}-${p.id}-name`}
              as="span"
              flex="0 99999 auto" // Set flex-shrink really high because parent names are less important than the feature name
              fontWeight="normal"
              minW="22px"
              isTruncated
            >
              {p.name}
            </Box>,
            <Box
              key={`parent-${feature.id}-${p.id}-slash`}
              as="span"
              color="dimmed"
              flex="0 0 auto"
              fontWeight="normal"
            >
              /
            </Box>,
          ])
        : null}
      <Box as="span" flex="0 1 auto" isTruncated>
        {featureName}
      </Box>
    </Flex>
  );
}
