import { Link as RouterLink } from "react-router-dom";
import { chakra, HStack, Link, StackProps, Text } from "@chakra-ui/react";

import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";

const EnvironmentIcon = chakra(EnvironmentSvg);

type Props = {
  environment: Pick<EnvironmentDTO, "name" | "isProduction">;
  link?: boolean;
} & StackProps;

export const EnvironmentDisplayName = ({
  environment,
  link = false,
  ...stackProps
}: Props) => {
  const { currentEnv } = useAuthContext();
  const colors = useEnvironmentColors();
  const envColor =
    colors[environment.isProduction ? "production" : "nonProduction"];

  const component = (
    <HStack spacing={1} {...stackProps}>
      <EnvironmentIcon boxSize={3} color={envColor.icon} />
      <Text as="span" color={envColor.text} fontWeight="medium">
        {environment.name}
      </Text>
    </HStack>
  );

  if (link && currentEnv) {
    return (
      <Link
        as={RouterLink}
        style={{ textDecoration: "none" }}
        to={GlobalSettingsUrl(currentEnv, "app-environments")}
      >
        {component}
      </Link>
    );
  } else {
    return component;
  }
};
