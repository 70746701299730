import { useEffect, useMemo, useState } from "react";
import { ColumnSort } from "@tanstack/react-table";
import equal from "fast-deep-equal";

import { ColumnState } from "@bucketco/shared/types/columns";

import { useFeatureViewData } from "@/app/data/useFeatureViewData";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function useFeatureViewColumnStatesLegacy(viewId?: string | null) {
  const [columnStates, _setColumnStates] = useState<ColumnState[]>([]);
  const [persistedStates, _setPersistedStates] = useState<ColumnState[]>([]);

  const [columnSort, _setColumnSort] = useState<ColumnSort>(defaultSort);
  const [persistedSort, _setPersistedSort] = useState<ColumnSort>(defaultSort);

  const { data: featureView, isFetching: isColumnStatesFetching } =
    useFeatureViewData(viewId);

  const setColumnStates = (
    nextColumnStates: ColumnState[],
    isPersisted: boolean = false,
    isInitial: boolean = false,
  ) => {
    _setColumnStates(nextColumnStates);

    if (isPersisted) {
      _setPersistedStates(nextColumnStates);
    }

    if (!isInitial) {
      const event = isPersisted ? "Columns saved" : "Columns changed";
      segmentAnalytics.track(event, {
        view: "feature view",
      });
    }
  };

  const setColumnSort = (
    nextColumnSort: ColumnSort,
    isPersisted: boolean = false,
  ) => {
    _setColumnSort(nextColumnSort);

    if (isPersisted) {
      _setPersistedSort(nextColumnSort);
    }
  };

  useEffect(() => {
    if (featureView) {
      setColumnStates(featureView.columns, true, true);

      if (featureView.columnSortKey) {
        const sort = {
          id: featureView.columnSortKey,
          desc: Boolean(featureView.columnSortDesc),
        };

        setColumnSort(sort, true);
      }
    }
  }, [featureView]);

  const isColumnStatesDirty = useMemo(() => {
    return (
      !equal(columnStates, persistedStates) || !equal(columnSort, persistedSort)
    );
  }, [columnStates, persistedStates, columnSort, persistedSort]);

  return {
    columnStates,
    setColumnStates,
    columnSort,
    setColumnSort,
    isColumnStatesDirty,
    isColumnStatesFetching,
  };
}

const defaultSort = {
  id: "createdAt",
  desc: true,
};
