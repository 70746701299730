import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import {
  buttonSizes,
  tagVariantClickable,
  tagVariantOutline,
  tagVariantSolid,
  tagVariantSubtle,
} from "@/theme/helpers";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
);

export const TagTheme = defineMultiStyleConfig({
  baseStyle: {
    container: {
      fontWeight: "normal",
    },
  },
  sizes: {
    "2xs": {
      container: {
        px: 2,
        py: 0.25,
        borderRadius: "base",
      },
    },
    xs: {
      container: {
        minH: buttonSizes.xs.h,
        fontSize: buttonSizes.sm.fontSize,
        px: 2,
        py: 1.5,
        borderRadius: "md",
      },
    },
    sm: {
      container: {
        minH: buttonSizes.sm.h,
        fontSize: buttonSizes.sm.fontSize,
        px: 3,
        py: 1.5,
        borderRadius: "md",
      },
    },
    md: {
      container: {
        minH: buttonSizes.md.h,
        px: 4,
        py: 1.5,
      },
    },
    lg: {
      container: {
        minH: buttonSizes.lg.h,
        py: 1.5,
      },
    },
  },
  variants: {
    solid: (props) => ({
      container: tagVariantSolid(props),
    }),
    subtle: (props) => ({
      container: tagVariantSubtle(props),
    }),
    outline: (props) => ({
      container: tagVariantOutline(props),
    }),
    clickable: (props) => ({
      container: tagVariantClickable(props),
    }),
  },
});
